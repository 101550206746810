import {Component, Injector, OnInit} from '@angular/core';
import {FormTemplate} from '../../../../shared/components/form.template';
import {RoomGroup} from '../../../../shared/models/room-group';
import {RoomGroupService} from '../../../services/room-group.service';
import {EnumService} from '../../../../shared/services/enum.service';
import {Observable, of} from 'rxjs';
import {EnumItem, Option} from '../../../../shared/models/app';
import {Room} from '../../../../shared/models/room';
import {Image} from '../../../../shared/models/image';
import {_indexOf} from '../../../../shared/helpers';
import {AttributeService} from '../../../services/attribute.service';
import {Attribute} from '../../../../shared/models/attribute';
import {ConfigurationService} from '../../../services/configuration.service';

@Component({
  selector: 'app-room-group-form',
  templateUrl: './room-group-form.component.html',
  styleUrls: ['./room-group-form.component.scss']
})
export class RoomGroupFormComponent extends FormTemplate<RoomGroup> implements OnInit {

  resourceName = 'pokój';
  resource = new RoomGroup();
  roomGroupStandards: Observable<EnumItem[]> = of([]);
  roomGroupTypes: Observable<EnumItem[]> = of([]);
  priceTables: Observable<EnumItem[]> = of([]);
  advancePayments: Observable<EnumItem[]> = of([]);
  relatedRooms: Option[] = [];
  relatedAttributes: Option[] = [];
  availableAttributes: Option[] = [];
  advancePayment;

  constructor(
    private configurationService: ConfigurationService,
    private rgtService: RoomGroupService,
    private enumService: EnumService,
    private attributeService: AttributeService,
    private injector: Injector
  ) {
    super(injector);
    this.service = this.rgtService;
  }

  ngOnInit() {
    super.ngOnInit();

    this.roomGroupStandards = this.enumService.roomGroupStandards();
    this.roomGroupTypes = this.enumService.roomGroupTypes();
    this.priceTables = this.enumService.priceTables();
    this.advancePayments = this.enumService.advancePayments();

    this.attributeService.listAll()
      .subscribe(response => this.availableAttributes = response.data.map<Option>(attribute => ({
        id: attribute.id,
        content: attribute.content
      } as Option)));

    this.configurationService.get().subscribe(response => this.advancePayment = response.data.advance_payment);
  }

  getItem(id: number) {
    this.service.get(id)
      .subscribe(resource => {
          this.resource = resource.data;
          this.relatedRooms = this.resource.rooms.map(room => ({id: room.id, content: room.name} as Option));
          this.relatedAttributes = this.resource.attributes.map(attribute => attribute as Option);
        }
      );
  }

  onImageDelete(event: Event) {
    const idx = _indexOf(this.resource.images, event, 'id');

    if (idx > -1) {
      this.resource.images.splice(idx, 1);
    }
  }

  onSuccess(event: Event) {
    const response = JSON.parse(event[0].xhr.response).data as Image;

    this.resource.images.push(response);
  }

  onRoomChanges(rooms: Option[]) {
    this.resource.rooms = rooms.map(room => ({name: room.content} as Room));
  }

  onAttributeChanges(options: Option[]) {
    this.resource.attributes = options.map(option => ({content: option.content, id: option.id} as Attribute));
  }

  onSubmit() {
    this.resource.price *= 100;
    this.resource.price_for_additional_person *= 100;
    this.resource.advance_payment_value *= 100;
    super.onSubmit();
    this.resource.price /= 100;
    this.resource.price_for_additional_person /= 100;
    this.resource.advance_payment_value /= 100;

    return;
  }

}
