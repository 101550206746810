import {Inject, Injectable} from '@angular/core';
import {RoomsAvailability} from '../../shared/models/rooms-availability';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config';
import {catchError, finalize, map} from 'rxjs/operators';

import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {MessageService} from '../../shared/services/message.service';
import {ResourceResponse} from '../../shared/DTOs/data-source.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RoomsAvailabilityService {

  protected loadingSubject = new BehaviorSubject<boolean>(false);

  url = '/rooms-availability';

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    @Inject(APP_CONFIG) protected config: AppConfig
  ) {
  }

  get(now: moment.Moment, to: moment.Moment) {
    const dateFormat = 'YYYY-MM';
    return this.http.get<{ data: RoomsAvailability[] }>(`${this.config.api}${this.url}?from=${now.format(dateFormat)}&to=${to.format(dateFormat)}`).pipe(
      catchError(() => of({data: null})),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  update(roomGroupId: string, dateAt: string, available: number): Observable<ResourceResponse | void> {
    return this.http.post<ResourceResponse>(`${this.config.api}${this.url}`, {
      'roomGroupId': roomGroupId,
      'dateAt': dateAt,
      'available': available
    });
  }
}
