import { Component, Injector } from '@angular/core';
import { ListTemplate } from '../../../../shared/components/list.template';
import { Agreement } from '../../../../shared/models/agreement';
import { AgreementService } from '../../../services/agreement.service';

@Component({
  selector: 'app-agreement-list',
  templateUrl: './agreement-list.component.html',
  styleUrls: ['./agreement-list.component.scss']
})
export class AgreementListComponent extends ListTemplate<Agreement> {

  displayedColumns = ['name', 'description', 'required', 'actions'];
  resourceName = 'Zgody';

  constructor(
    public agreementService: AgreementService,
    private injector: Injector,
  ) {
    super(injector);

    this.service = this.agreementService;
  }

}
