import {Injectable} from '@angular/core';
import {ResourceService} from '../../shared/services/resource.service';
import {RoomGroup} from '../../shared/models/room-group';
import {Attribute} from '../../shared/models/attribute';

@Injectable({
  providedIn: 'root'
})
export class RoomGroupService extends ResourceService<RoomGroup> {
  url = '/room-groups';

  listAll() {
    return this.http.get<{ data: Attribute[] }>(`${this.config.api}${this.url}`);
  }
}
