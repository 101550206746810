import { Injectable } from '@angular/core';
import {ResourceService} from '../../shared/services/resource.service';
import {PhoneToNotify} from '../../shared/models/phone-to-notify';
import {Observable, of} from 'rxjs';
import {DATA_PLACEHOLDER, DataSourceModel} from '../../shared/DTOs/data-source.model';
import {catchError, finalize} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhoneToNotifyService extends ResourceService<PhoneToNotify> {
  url = '/phone-to-notify';

  getPhones(): Observable<{ data: PhoneToNotify[] } | DataSourceModel> {
    this.loadingSubject.next(true);

    return this.http.get<{ data: PhoneToNotify[] }>(`${this.config.api}${this.url}`).pipe(
      catchError(() => of(DATA_PLACEHOLDER)),
      finalize(() => this.loadingSubject.next(false))
    );
  }
}
