import {Attribute} from './attribute';
import {Image} from './image';
import {RoomGroup} from './room-group';

export class Package {
  id: number;
  name: string;
  description: string;
  price_from: number;
  present_from: Date | string;
  present_to: Date | string;
  available_from: Date | string;
  available_to: Date | string;
  strict_dates: boolean;
  images: Image[];
  attributes: Attribute[];
  package_room_group: PackageRoomGroup[];
  availability_status: number;
  created_at?: string;
  updated_at?: string;

  constructor() {
    this.id = this.name = this.description = this.price_from = this.present_from = this.present_to = this.available_from
      = this.available_to = this.created_at = this.updated_at = null;

    this.strict_dates = false;
    this.package_room_group = [];
    this.images = [];
    this.attributes = [];
    this.availability_status = 0;
  }
}

export class PackageRoomGroup {
  id: number;
  name: string;
  price: number;
  price_for_additional_person: number;
  advance_price: number;
  room_group_id: number;
  room_group: RoomGroup[];
  selected: boolean;

  constructor() {
    this.id = this.name = this.price = this.price_for_additional_person = this.room_group_id = null;
    this.room_group = [];
    this.selected = false;
  }
}
