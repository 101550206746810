import {Component, Injector} from '@angular/core';
import {ListTemplate} from '../../../../shared/components/list.template';
import {Settlement} from '../../../../shared/models/settlement';
import {SettlementService} from '../../../services/settlement.service';
import {AdminSettlementService} from '../../../services/admin-settlement.service';

@Component({
  selector: 'app-bse-settelment-list',
  templateUrl: './bse-settelment-list.component.html',
  styleUrls: ['./bse-settelment-list.component.scss']
})
export class BseSettelmentListComponent extends ListTemplate<Settlement> {

  resourceName = 'Rozliczenie';
  displayedColumns = ['status', 'hotel', 'dates', 'to_paid', 'actions'];

  constructor(
    private settlementService: SettlementService,
    private adminSettlementService: AdminSettlementService,
    private injector: Injector
  ) {
    super(injector);
    this.service = adminSettlementService;
  }

  open(settlement: Settlement) {
    this.settlementService.getInvoice(settlement.id).subscribe((response) => {
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL, '_blank');
    });
  }

  sendInvoice(settlement: Settlement) {
    this.messageService.confirm('Czy napewno chcesz wysłać fakturę?', '', 'Wyślij', 'Anuluj', () => {
      this.adminSettlementService.markInvoiceSent(settlement.id).subscribe(response => {
        if (response.status === 0) {
          this.messageService.toast(response.message);
          this.dataSource.data$.subscribe(data => {
            if (typeof data.data === 'object') {
              const a = data.data.find(x => x.id === settlement.id);
              a.status = 200;
            }
          });
        }
      }, error => {
        this.messageService.toast(error.message);
      });
    });
  }

  markSettled(settlement: Settlement) {
    this.messageService.confirm('Czy napewno chcesz zmienić status?', '', 'Zmień status', 'Anuluj', () => {
      this.adminSettlementService.markSettled(settlement.id).subscribe(response => {
        this.messageService.toast(response.message);
        this.dataSource.data$.subscribe(data => {
          if (typeof data.data === 'object') {
            const a = data.data.find(x => x.id === settlement.id);
            a.status = 900;
          }
        });
      }, error => {
        this.messageService.toast(error.message);
      });
    });
  }
}
