import { DataSourceModel, DATA_PLACEHOLDER } from './data-source.model';
import { DataSource as CdkDataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgForm } from '@angular/forms';

export class DataSource<T> implements CdkDataSource<T> {

  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private responseSubject = new BehaviorSubject<DataSourceModel>(DATA_PLACEHOLDER);

  private service: any;

  public loading$ = this.loadingSubject.asObservable();
  public data$ = this.responseSubject.asObservable();

  constructor(service: any) {
    this.service = service;
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(page = 1, filters = {}) {
    this.loadingSubject.next(true);

    Object.keys(filters).map((key, index) => {
      const value = filters[key];

      filters[key] = !value ? '' : value;
    });

    this.service.getAll(page, filters)
      .pipe(
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((response: DataSourceModel) => {
        this.dataSubject.next(response.data as T[]);
        this.responseSubject.next(response);
      });
  }

  clearFilters(page = 1, form: NgForm) {
    form.resetForm();

    this.loadData(page);
  }
}
