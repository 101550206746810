import {Client} from './client';
import {Room} from './room';
import {RoomGroup} from './room-group';
import {Package} from './package';

export class Reservation {
  id: number;
  client: Client;
  room: Room;
  room_group: RoomGroup;
  status: number;
  display_status: string;
  payment_method: string;
  package: Package;
  price: number;
  advance_payment_price: number;
  to_pay: number;
  paid: number;
  adults: number;
  children: number;
  comments: string;
  date_from: string;
  date_to: string;
  commission?: number;
}
