import { Component, OnInit, Injector } from '@angular/core';
import { FormTemplate } from '../../../../shared/components/form.template';
import { RoomGroupType } from '../../../../shared/models/room-group-type';
import { RoomGroupTypeService } from '../../../services/room-group-type.service';

@Component({
  selector: 'app-rgt-form',
  templateUrl: './rgt-form.component.html',
  styleUrls: ['./rgt-form.component.scss']
})
export class RgtFormComponent extends FormTemplate<RoomGroupType> {

  resourceName = 'typ pokoju';
  resource = new RoomGroupType();

  constructor(
    private rgtService: RoomGroupTypeService,
    private injector: Injector
  ) {
    super(injector);
    this.service = this.rgtService;
  }

}
