import { Component, Injector} from '@angular/core';
import { DashboardStatsService } from '../../services/dashboard-stats.service';
import { DashboardBrickStats } from '../../../shared/models/stats/dashboard-brick-stats';
import { DashboardSalesStats } from '../../../shared/models/stats/dashboard-sales-stats';
import { Hotel } from '../../../shared/models/hotel';
import { LocalStorage } from '../../../shared/services/local-storage.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {AuthService} from '../../../shared/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  resourceBrickStats = new DashboardBrickStats();
  resourceSalesStats = new DashboardSalesStats();

  private localStorage: LocalStorage;
  readonly currHotel;

  // Bar chart
  public lineChartData: ChartDataSets[] = [{}];
  public lineChartLabels: Label[] = [];

  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [{}]
    },
  };

  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(38,130,224,0.2)',
      borderColor: 'rgba(38,130,224,1)',
      pointBackgroundColor: 'rgba(38,130,224,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
  ];

  public lineChartLegend = true;
  public lineChartType = 'line';

  // Bar chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
  };

  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [{ data: [] }];

  constructor(
    private injector: Injector,
    public statsService: DashboardStatsService,
    private authService: AuthService,
  ) {
    this.localStorage = injector.get(LocalStorage);
    this.currHotel = this.localStorage.getItem('bse_hotel');

    if (this.currHotel && (authService.getUser().hasRole('superadministrator') || authService.getUser().hasRole('hotel_admin'))) {
      this.getDashboardBrickStats();
      this.getDashboardSalesStats();
    }
  }

  getDashboardBrickStats() {
    this.statsService.headers.append('Hotel', atob(this.currHotel).toString());
    this.statsService.getDashboardBrickStats()
      .subscribe(response => {
        this.resourceBrickStats = response.data;
      });
  }

  getDashboardSalesStats() {
    this.statsService.headers.append('Hotel', atob(this.currHotel).toString());
    this.statsService.getDashboardSalesStats()
      .subscribe(response => {
        this.resourceSalesStats = response.data;
        this.lineChartLabels = response.data[0].labels;
        this.barChartLabels = response.data[0].labels;
        this.lineChartData = [{data: response.data[1].sales, label: 'Sprzedaż'}];
        this.barChartData = [{data: response.data[2].income, label: 'Przychód ze sprzedaży'}];
      });
  }
}
