import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpClient } from '@angular/common/http';
import { LocalStorage } from '../../shared/services/local-storage.service';

@Injectable()
export class HotelInterceptor implements HttpInterceptor {

  private localStorage: LocalStorage;

  constructor(private injector: Injector) {
    this.localStorage = this.injector.get(LocalStorage);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;

    if (!headers.has('hotel')) {
      const hotel = this.localStorage.getItem('bse_hotel');
      const hash = this.localStorage.getItem('bse_hash');

      if (hotel) {
        headers = headers.append('Hotel', atob(hotel));
      }

      if (hash) {
        headers = headers.append('Hash', hash);
      }
    }

    return next.handle(req.clone({
      headers
    }));
  }
}
