import { Component, Injector } from '@angular/core';
import { ListTemplate } from '../../../../shared/components/list.template';
import { PriceTable } from '../../../../shared/models/price-table';
import { PriceTableService } from '../../../services/price-table.service';

@Component({
  selector: 'app-price-table-list',
  templateUrl: './price-table-list.component.html',
  styleUrls: ['./price-table-list.component.scss']
})

export class PriceTableListComponent extends ListTemplate<PriceTable> {

  displayedColumns = ['name', 'price', 'created_at', 'actions'];
  resourceName = 'Plany Cenowe';

  constructor(
    public priceTableService: PriceTableService,
    private injector: Injector,
  ) {
    super(injector);

    this.service = this.priceTableService;
  }

}

