import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Option } from '../../../shared/models/app';
import { _indexOf } from '../../../shared/helpers';

@Component({
  selector: 'app-typeahead-chips',
  templateUrl: './typeahead-chips.component.html',
  styleUrls: ['./typeahead-chips.component.scss']
})
export class TypeaheadChipsComponent implements OnInit {

  control = new FormControl();

  readonly separatorKeysCodes: number[] = [ENTER];

  @ViewChild('optionInput', { static: true }) optionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  @Input('placeholder') placeholder: string;
  @Input('hint') hint: string;
  @Input('options') options: Option[];
  @Input('selectedOptions') selectedOptions: Option[];
  filteredOptions: Option[];

  @Output('valueChanges') valueChanges: EventEmitter<Option[]> = new EventEmitter<Option[]>();

  constructor() { }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (this.filteredOptions.length > 1) {
      return;
    }

    let toAdd: Option;

    if ((!this.filteredOptions.length && (value || '').trim()) || this.filteredOptions[this.filteredOptions.length - 1].content.toLowerCase() !== value.trim().toLowerCase()) {
      toAdd = { id: null, content: value.trim() };
    } else {
      toAdd = this.filteredOptions.pop();
    }

    if (_indexOf(this.selectedOptions, toAdd, 'content') !== -1) {
      return;
    }

    this.selectedOptions.push(toAdd);

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this._emitValues();
  }

  remove(option: Option): void {
    const index = _indexOf(this.selectedOptions, option, 'content');

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);

      this._emitValues();
    }
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const toAdd: Option = { id: event.option.value, content: event.option.viewValue };

    if (_indexOf(this.selectedOptions, toAdd, 'content') !== -1) {
      return;
    }

    this.selectedOptions.push(toAdd);
    this.optionInput.nativeElement.value = '';
    this.control.setValue(null);

    this._emitValues();
  }

  ngOnInit() {
    this.filteredOptions = this._filter('');
    this.control.valueChanges
      .subscribe(value => {
        if (typeof value !== 'string') {
          return;
        }
        this.filteredOptions = this._filter(value);
      });
  }

  private _filter(value: string): Option[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.content.toLowerCase().includes(filterValue));
  }

  private _emitValues() {
    this.valueChanges.next(this.selectedOptions);
  }
}
