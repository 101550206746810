import { MessageService } from '../../shared/services/message.service';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { Configuration } from '../../shared/models/configuration';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ResourceResponse } from '../../shared/DTOs/data-source.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  get() {
    this.loadingSubject.next(true);

    return this.http.get<{ data: Configuration }>(`${this.config.api}/configuration`)
      .pipe(
        catchError(() => of({ data: new Configuration() })),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  update(resource: Configuration) {
    this.loadingSubject.next(true);

    return this.http.post<ResourceResponse>(`${this.config.api}/configuration`, resource)
      .pipe(
        map(response => this.messageService.toast(response.message)),
        finalize(() => this.loadingSubject.next(false))
      );
  }
}
