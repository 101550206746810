import {Component, Injector} from '@angular/core';
import {FormTemplate} from '../../../../shared/components/form.template';
import {Attribute} from '../../../../shared/models/attribute';
import {AttributeService} from '../../../services/attribute.service';

@Component({
  selector: 'app-attribute-form',
  templateUrl: './attribute-form.component.html',
  styleUrls: ['./attribute-form.component.scss']
})
export class AttributeFormComponent extends FormTemplate<Attribute> {
  resourceName = 'Udogodnienia';
  resource = new Attribute();

  constructor(
    public attributeService: AttributeService,
    private injector: Injector
  ) {
    super(injector);
    this.service = this.attributeService;
  }

  public setIcon(icon: string) {
    this.resource.icon = icon;
  }
}
