import { OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '../services/layout.service';
import { AppConfig, APP_CONFIG } from '../../app.config';
import { AuthService } from '../services/auth.service';

export abstract class BaseTemplate implements OnInit {
  protected resourceId: number;
  protected abstract resourceName: string;

  protected route: ActivatedRoute;
  protected router: Router;
  protected layoutService: LayoutService;
  protected authService: AuthService;
  protected config: AppConfig;

  constructor(injector: Injector) {
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.layoutService = injector.get(LayoutService);
    this.config = injector.get(APP_CONFIG);
    this.authService = injector.get(AuthService);
  }

  ngOnInit() {
    this.resourceId = +this.route.snapshot.paramMap.get('id');
    this.layoutService.setTitle(this.resourceName);
  }
}
