import { Injectable } from '@angular/core';
import {ResourceService} from '../../shared/services/resource.service';
import {Calendar} from '../../shared/models/calendar';

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends ResourceService<Calendar> {
  url = '/calendars';
}
