import {Component, Injector} from '@angular/core';
import {ListTemplate} from '../../../../shared/components/list.template';
import {RoomGroup} from '../../../../shared/models/room-group';
import {RoomGroupService} from '../../../services/room-group.service';

@Component({
  selector: 'app-room-group-list',
  templateUrl: './room-group-list.component.html',
  styleUrls: ['./room-group-list.component.scss']
})
export class RoomGroupListComponent extends ListTemplate<RoomGroup> {

  displayedColumns = ['picture', 'name', 'room_group_standard', 'prices', 'actions'];
  resourceName = 'Pokoje';

  constructor(
    public rgtService: RoomGroupService,
    private injector: Injector,
  ) {
    super(injector);

    this.service = this.rgtService;
  }

}
