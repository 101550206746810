import {Injectable} from '@angular/core';
import {ResourceService} from '../../shared/services/resource.service';
import {Reservation} from '../../shared/models/reservation';
import {Configuration} from '../../shared/models/configuration';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs';
import {DATA_PLACEHOLDER} from '../../shared/DTOs/data-source.model';

@Injectable({
  providedIn: 'root'
})
export class ReservationService extends ResourceService<Reservation> {

  url = '/reservations';

  getPending() {
    this.loadingSubject.next(true);

    return this.http.get<{ data: Reservation }>(`${this.config.api}/filtered/reservations?status=200`)
      .pipe(
        catchError(() => of(DATA_PLACEHOLDER)),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  getReservationsForCalendar() {
    this.loadingSubject.next(true);

    return this.http.get<{ data: Reservation }>(`${this.config.api}/calendar-reservations`)
      .pipe(
        catchError(() => of(DATA_PLACEHOLDER)),
        finalize(() => this.loadingSubject.next(false))
      );
  }

}
