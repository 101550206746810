import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {

  private authService: AuthService;
  private router: Router;

  constructor(
    private injector: Injector,
  ) {
    this.authService = this.injector.get(AuthService);
    this.router = this.injector.get(Router);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isValid()) {
      this.router.navigate(['/']);

      return false;
    }

    return true;
  }
}
