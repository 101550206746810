import { Component, Injector } from '@angular/core';
import { FormTemplate } from '../../../../shared/components/form.template';
import { RoomGroupStandard } from '../../../../shared/models/room-group-standard';
import { RoomGroupStandardService } from '../../../services/room-group-standard.service';

@Component({
  selector: 'app-rgs-form',
  templateUrl: './rgs-form.component.html',
  styleUrls: ['./rgs-form.component.scss']
})
export class RgsFormComponent extends FormTemplate<RoomGroupStandard> {

  resourceName = 'standard pokoju';
  resource = new RoomGroupStandard();

  constructor(
    public rgtService: RoomGroupStandardService,
    private injector: Injector
  ) {
    super(injector);
    this.service = this.rgtService;
  }

}
