import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Image } from '../../shared/models/image';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { ResourceResponse } from '../../shared/DTOs/data-source.model';

@Injectable({
  providedIn: 'root'
})
export class DzUploaderService {

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient
  ) { }

  deleteImage(image: Partial<Image>) {
    return this.http.delete<ResourceResponse>(`${this.config.api}/images/${image.id}`);
  }

  reorderImages(images: Partial<Image[]>) {
    return this.http.post<{ data: Image }>(`${this.config.api}/images/order/`, images);
  }

}
