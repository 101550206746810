import {Component, Injector, OnInit} from '@angular/core';
import {toggleFade} from '../../../../widget/animations';
import {RoomGroup} from '../../../../shared/models/room-group';
import {RoomGroupService} from '../../../services/room-group.service';
import {FormTemplate} from '../../../../shared/components/form.template';
import {CalendarService} from '../../../services/calendar.service';
import {Calendar} from '../../../../shared/models/calendar';
import {MessageService} from '../../../../shared/services/message.service';
import {CopyInputService} from '../../../../shared/services/copy-input.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-room-group-info',
  templateUrl: './room-group-calendar-list.component.html',
  styleUrls: ['./room-group-calendar-list.component.scss'],
  animations: [
    toggleFade
  ]
})
export class RoomGroupCalendarListComponent extends FormTemplate<RoomGroup> implements OnInit {
  resourceName = 'Kalendarze';
  addCalendar = false;

  resource = new RoomGroup();
  resourceCalendar = new Calendar();
  protected messageService: MessageService;

  constructor(
    private injector: Injector,
    protected router: Router,
    private rgtService: RoomGroupService,
    public calendarService: CalendarService,
    private alert: MessageService,
    public copy: CopyInputService,
  ) {
    super(injector);
    this.service = this.rgtService;
    this.messageService = injector.get(MessageService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getItem(id: number) {
    this.service.get(id)
      .subscribe(resource => {
          this.resource = resource.data;
        }
      );
  }

  deleteCalendar(id: number) {
    this.messageService.confirm('Czy na pewno chcesz kontynuować?', 'Potwierdź czynność', null, null, () =>
      this.calendarService.delete(id)
        .subscribe(() => {
          this.alert.toast('Kalendarz został usunięty.');
          this.router.navigate(['/room-groups/' + this.resource.id + '/calendar'], {skipLocationChange: true});
        }, error => {
          this.alert.toast('Nie można było usunąć kalendarza. Spróbój ponownie.');
        })
    );
  }

  onSubmit() {
    this.resourceCalendar.room_group_id = this.resource.id;

    this.calendarService.create(this.resourceCalendar)
      .subscribe(() => {
        this.alert.toast('Kalendarz został dodany');
        this.router.navigate(['/room-groups/' + this.resource.id + '/calendar'], {skipLocationChange: true});
      }, () => {
        this.alert.toast('Nie można było dodać kalendarza. Spróbój ponownie.');
      });
  }

  calendarAction() {
    this.addCalendar = !this.addCalendar;
  }
}
