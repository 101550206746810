import { Component, Injector } from '@angular/core';
import { FormTemplate } from '../../../../shared/components/form.template';
import { Agreement } from '../../../../shared/models/agreement';
import { AgreementService } from '../../../services/agreement.service';

@Component({
  selector: 'app-agreement-form',
  templateUrl: './agreement-form.component.html',
  styleUrls: ['./agreement-form.component.scss']
})
export class AgreementFormComponent extends FormTemplate<Agreement> {

  resourceName = 'zgodę';
  resource = new Agreement();

  constructor(
    public agreementService: AgreementService,
    private injector: Injector
  ) {
    super(injector);
    this.service = this.agreementService;
  }

}
