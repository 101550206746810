import {Component, Injector, OnInit} from '@angular/core';
import {FormTemplate} from '../../../../shared/components/form.template';
import {User} from '../../../../shared/models/user';
import {UserService} from '../../../services/user.service';
import {EnumItem} from '../../../../shared/models/app';
import {Role} from '../../../../shared/models/role';
import {UserUpdate} from '../../../../shared/DTOs/user-update';
import {EnumService} from '../../../../shared/services/enum.service';
import {AuthService} from '../../../../shared/services/auth.service';
import {Hotel} from '../../../../shared/models/hotel';
import {toggleFade} from '../../../../widget/animations';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  animations: [
    toggleFade
  ]
})
export class UserFormComponent extends FormTemplate<User> implements OnInit {

  resourceName = 'użytkownika';
  resource = new UserUpdate();
  roles: EnumItem[] = [];
  selectedRoles: number[] = [];
  hotels: Hotel[];
  hotel_id = '';
  creating = false;
  passwordChange = false;

  constructor(
    private userService: UserService,
    private injector: Injector,
    private enumService: EnumService,
    public authService: AuthService
  ) {
    super(injector);
    this.service = this.userService;
    this.hotels = this.authService.user.hotels;
  }

  ngOnInit() {
    super.ngOnInit();
    this.enumService.roles().subscribe(roles => this.roles = roles);

    if (this.router.url.split('/')[2] === 'create') {
      this.passwordChange = true;
      this.creating = true;
    }
  }

  getItem(id: number) {
    this.service.get(id)
      .subscribe(resource => {
        this.resource = resource.data as UserUpdate;
        if (this.resource) {
          this.selectedRoles = this.resource.roles.map(role => role.id);
        }
      });
  }

  onSubmit() {
    this.userService.headers = this.userService.headers.set('Hotel', this.hotel_id.toString());

    this.resource.roles = this.selectedRoles.map((role) => new Role(role));

    if (this.resourceId) {
      this.service.update(this.resourceId, this.resource)
        .subscribe();

      return;
    }

    this.service.create(this.resource)
      .subscribe();
  }

  get isHotelAdmin() {
    return this.selectedRoles.some(roleId => (this.roles || []).filter(role => role.value === 'hotel_admin').map(role => role.id).indexOf(roleId) !== -1);
  }

  get isSuperAdmin() {
    return this.authService.getUser().onlyRole('superadministrator');
  }

  passwordToggle() {
    this.passwordChange = !this.passwordChange;
  }
}
