import {Component, Injector} from '@angular/core';
import {ListTemplate} from '../../../../shared/components/list.template';
import {Hotel} from '../../../../shared/models/hotel';
import {HotelService} from '../../../services/hotel.service';
import {AuthService} from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-hotel-list',
  templateUrl: './hotel-list.component.html',
  styleUrls: ['./hotel-list.component.scss']
})
export class HotelListComponent extends ListTemplate<Hotel> {

  displayedColumns = ['picture', 'name', 'created_at', 'updated_at', 'actions'];
  resourceName = 'Hotele';

  constructor(
    public rgtService: HotelService,
    private authService: AuthService,
    private injector: Injector,
  ) {
    super(injector);

    this.service = this.rgtService;
  }

  deleteResource(id: number) {
    this.messageService.confirm('Czy na pewno chcesz kontynuować?', 'Potwierdź czynność', null, null, () =>
      this.service.delete(id)
        .subscribe(() => {
          this.dataSource.loadData(this.paginator.pageIndex + 1, this.filters);

          this.authService.updateUser();
        })
    );
  }

  get canCreate() {
    const user = this.authService.getUser();
    return (user.hotels.length < user.hotels_count) || user.hasRole('superadministrator');
  }

}
