import { Component, OnInit, Injector, ViewEncapsulation } from '@angular/core';
import { LayoutTemplate } from '../../../shared/components/layout.template';
import { MatSelectChange } from '@angular/material';
import { BsEngineService } from '../../../shared/services/bsEngine.service';
import {EnumService} from '../../../shared/services/enum.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent extends LayoutTemplate implements OnInit {

  activeRouter;
  selectedHotel: number;

  constructor(
    private injector: Injector,
    private bsEngineService: BsEngineService,
    private enumServices: EnumService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    const currHotel = atob(this.localStorage.getItem('bse_hotel'));

    if (currHotel) {
      this.selectedHotel = parseInt(currHotel, 10);
    }

    this.subscriptions.menuChanges = this.appService.menuChanges$.subscribe(() => this.updateHotelList());

    if (this.hotels.length === 1) {
      this.selectedHotel = this.hotels[0].id;
      this.setHotel(this.selectedHotel.toString());
    }

    this.activeRouter = '/' + this.router.url.split('/')[1];
  }

  setHotel(hotel: string = '') {
    this.localStorage.setItem('bse_hotel', btoa(encodeURIComponent(hotel)));

    this.enumServices.clearEnums();
    this.enumServices.refresh();

    this.bsEngineService.getHash().subscribe(
      response => {
        this.localStorage.setItem('bse_hash', response.data.hash);
        this.bsEngineService.setHash = response.data.hash;
      }
    );
  }

  hotelChanged(event: MatSelectChange) {
    this.setHotel(event.value);
    this.router.navigate(['/']);

    this.messageService.toast('Zmieniono hotel. Przekierowano na stronę główną.');
  }

  updateHotelList() {
    const hotel = this.localStorage.getItem('bse_hotel');

    if (!hotel) {
      this.selectedHotel = null;

      return;
    }

    const decodedHotel = parseInt(decodeURIComponent(atob(hotel)), 10);

    if (this.selectedHotel === decodedHotel && !this.hotels.filter(h => h.id === this.selectedHotel).length) {
      this.localStorage.removeItem('bse_hotel');
      this.localStorage.removeItem('bse_hash');
      this.selectedHotel = null;
    }
  }

  get hotels() {
    return this.authService.getUser().hotels;
  }

  get menuEntries() {
    return this.appService.menu;
  }

}
