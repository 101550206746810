import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { MessageService } from '../services/message.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  private authService: AuthService;
  private messageService: MessageService;
  private router: Router;

  constructor(private injector: Injector) {
    this.authService = this.injector.get(AuthService);
    this.messageService = this.injector.get(MessageService);
    this.router = this.injector.get(Router);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (response) => response,
        (error: HttpErrorResponse) => {
          let redirect = false;
          let redirectTo = null;

          switch (error.status) {
            case 401:
              redirect = true;
              redirectTo = '/login';
              this.authService.removeToken();
              break;
            case 403:
              redirect = true;
              break;
            case 404:
              redirect = true;
              break;
            case 422:
              error.error.message = 'Formularz zawiera następujące błędy:<br><br>' + (error.error.message as string[]).join('<br>');
              break;
            default:
              error.error.message = 'Wystąpił nieoczekiwany błąd. Prosimy spróbować ponownie.';
              break;
          }

          this.messageService.info(error.error.message, 'Oops!');

          if (redirect) {
            this.router.navigate([redirectTo || '/']);
          }
        }
      )
    );
  }
}
