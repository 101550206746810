import { MatPaginator, MatSort, Sort, PageEvent } from '@angular/material';
import { ViewChild, AfterViewInit, OnInit, Injector } from '@angular/core';
import { DataSource } from '../DTOs/data-source';
import { merge } from 'rxjs';
import { IResourceService } from '../DTOs/data-source.model';
import { LayoutService } from '../services/layout.service';
import { MessageService } from '../services/message.service';

export abstract class ListTemplate<T> implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  dataSource: DataSource<T>;

  filters: any = {
    order: 'desc',
    by: 'id'
  };

  public service: IResourceService<T>;
  abstract resourceName: string;

  protected layoutService: LayoutService;
  protected messageService: MessageService;

  constructor(injector: Injector) {
    this.layoutService = injector.get(LayoutService);
    this.messageService = injector.get(MessageService);
  }

  ngAfterViewInit() {
    if (this.sort) {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

      merge(this.sort.sortChange, this.paginator.page)
        .subscribe((result: Sort | PageEvent) => {
          if ('active' in result) {
            this.filters['order'] = result.direction;
            this.filters['by'] = result.active;
          }

          this.dataSource.loadData(this.paginator.pageIndex + 1, this.filters);
        });
    }
  }

  ngOnInit() {
    this.layoutService.setTitle(this.resourceName);

    this.getResources();
  }

  getResources() {
    this.dataSource = new DataSource<T>(this.service);
    this.dataSource.loadData(1, this.filters);
  }

  deleteResource(id: number) {
    this.messageService.confirm('Czy na pewno chcesz kontynuować?', 'Potwierdź czynność', null, null, () =>
      this.service.delete(id)
        .subscribe(() => this.dataSource.loadData(this.paginator.pageIndex + 1, this.filters))
    );
  }

}
