import { AuthService } from './../services/auth.service';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);
    let headers: HttpHeaders;

    if (authService.isValid()) {

      if (authService.authLoginAsService.isValid()) {
        headers = req.headers;
        headers = headers.set('Authorization', `Bearer ${authService.authLoginAsService.token}`);
        headers = headers.set('Content-Type', 'application/json');

      } else {
        headers = req.headers;
        headers = headers.set('Authorization', `Bearer ${authService.token}`);
        headers = headers.set('Content-Type', 'application/json');

      }


      const tokenizedRequest = req.clone({
        headers
      });
      
      return next.handle(tokenizedRequest);
    }


    headers = req.headers;
    headers = headers.set('Content-Type', 'application/json');

    return next.handle(req.clone({
      headers
    }));
  }
}
