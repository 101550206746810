import { Injectable, Injector } from '@angular/core';
import {CanActivate, RouterStateSnapshot, Router, ActivatedRouteSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { MessageService } from '../../shared/services/message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private authService: AuthService;
  private messageService: MessageService;
  private router: Router;

  constructor(
    private injector: Injector,
  ) {
    this.authService = this.injector.get(AuthService);
    this.messageService = this.injector.get(MessageService);
    this.router = this.injector.get(Router);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles = next.data.roles;

    if (!this.authService.isValid()) {
      this.authService.removeToken();

      this.router.navigate(['/login']);

      return false;
    }

    if (allowedRoles && !this.authService.hasAnyRole(this.authService.getUser().roles, allowedRoles)) {
      if (this.router.routerState.snapshot.url === '') {
        this.router.navigate(['/']);
      }

      return true;
    }

    return true;
  }
}
