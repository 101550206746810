import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/services/resource.service';
import { User } from '../../shared/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ResourceService<User> {

  url = '/users';

}
