import { PriceTableService } from '../../../services/price-table.service';
import { PriceTable } from '../../../../shared/models/price-table';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UpdateValueDialogComponent } from '../../../../shared/components/update-value-dialog/update-value-dialog.component';

@Component({
  selector: 'app-month-table',
  templateUrl: './month-table.component.html',
  styleUrls: ['./month-table.component.scss']
})
export class MonthTableComponent implements OnInit, AfterViewInit {

  @Input('days') days: string[];
  @Input('month') month: string;
  @Input('monthIndex') monthIndex: number;
  @Input('resource') resource: PriceTable;

  tmpDays: { [day: string]: number } = {};
  selDays: string[] = [];
  monthId = 'month';
  price: string;

  constructor(public pts: PriceTableService, public dialog: MatDialog) { }
  ngOnInit() {
    this.pts.price$.subscribe(price => {

      const controls = Object.keys(this.tmpDays).filter(day => !this.resource.days[day]);
      controls.forEach(day => this.tmpDays[day] = price);

    });
    this.pts.dateRange$.subscribe(() => {
      this.updateAvailableDays(this.tmpDays);
      this.pts.priceChanged(this.resource.price);

    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const availableDays = {};

      this.days.forEach(day => availableDays[day] = this.resource.price);

      this.updateAvailableDays(availableDays);

      if (this.days[0]) {
        this.monthId = this.days[0];
      }

      this.pts.priceChanged(this.resource.price);
    });
  }

  onPriceChange(event: Event, day: string) {
    this.resource.days[day] = parseInt((event.target as HTMLInputElement).value, 10);
  }

  updateAvailableDays(days) {
    this.tmpDays = Object.assign(days, this.resource.days);
  }

  onCellSelect(day: string) {
    const added = this.toggleAddRemoveCell(day);
    const element = document.getElementById(this.monthId);

    if (element == null) {
      return;
    }

    const elements = element.getElementsByClassName('cellday-' + day);

    for (let i = 0; i < elements.length; i++) {
      if (added === true) {
        elements[i].classList.add('elselected');
      } else {
        elements[i].classList.remove('elselected');
      }
    }
  }

  updateSelectedDays(value: string) {
    for (let i = 0; i < this.selDays.length; i++) {
      this.tmpDays[this.selDays[i]] = parseInt(value, 10);
    }

    this.resource.days = this.tmpDays;
  }

  private toggleAddRemoveCell(day: string) {
    const strSelDays = JSON.stringify(this.selDays);
    if (strSelDays.indexOf(JSON.stringify(day)) === -1) {
      this.selDays.push(day);
      return true;
    } else {
      const index = this.selDays.indexOf(day);
      if (index > -1) {
        this.selDays.splice(index, 1);
        return false;
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UpdateValueDialogComponent, {
      data: {
        price: this.price
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateSelectedDays(result);
      }
    });
  }
}
