export class Calendar {
  id: number;
  name: string;
  calendar_url: string;
  room_group_id: number;
  last_sync: string;

  constructor() {
    this.id = this.name = this.calendar_url = this.room_group_id = this.last_sync = null;
  }
}
