import {Component, Injector, OnInit} from '@angular/core';
import {FormTemplate} from '../../../../shared/components/form.template';
import {Option} from '../../../../shared/models/app';
import {Image} from '../../../../shared/models/image';
import {_indexOf} from '../../../../shared/helpers';
import {AttributeService} from '../../../services/attribute.service';
import {Attribute} from '../../../../shared/models/attribute';
import {Package, PackageRoomGroup} from '../../../../shared/models/package';
import {PackageService} from '../../../services/package.service';
import {RoomGroupService} from '../../../services/room-group.service';
import * as moment from 'moment';
import {RoomGroup} from '../../../../shared/models/room-group';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {now} from 'moment';

@Component({
  selector: 'app-package-form',
  templateUrl: './package-form.component.html',
  styleUrls: ['./package-form.component.scss']
})
export class PackageFormComponent extends FormTemplate<Package> implements OnInit {

  resourceName = 'ofertę specjalną';
  resource = new Package();
  relatedAttributes: Option[] = [];
  availableAttributes: Option[] = [];
  daysPresentBeforeAvailable = 7;
  availableRoomGroups: PackageRoomGroup[] = [];
  bsConfigAvailable = {
    showWeekNumbers: false,
    containerClass: 'theme-default',
    locale: 'pl'
  } as unknown as BsDatepickerConfig;
  bsConfigPresent = {
    showWeekNumbers: false,
    containerClass: 'theme-default',
    locale: 'pl'
  } as unknown as BsDatepickerConfig;

  constructor(
    private packageService: PackageService,
    private attributeService: AttributeService,
    private roomGroupService: RoomGroupService,
    private injector: Injector
  ) {
    super(injector);
    this.service = this.packageService;
  }

  ngOnInit() {
    super.ngOnInit();

    this.attributeService.listAll().subscribe(response => this.availableAttributes = response.data.map<Option>(attribute => ({
      id: attribute.id,
      content: attribute.content
    } as Option)));

    if (!this.resourceId) {
      this.roomGroupService.getAll().subscribe(
        (response: { data: RoomGroup[] }) => response.data.forEach(
          item => this.resource.package_room_group.push(this.getRoomGroup(item))
        ));
    }
  }

  getItem(id: number) {
    this.service.get(id).subscribe(resource => {
        this.resource = resource.data;
        this.relatedAttributes = this.resource.attributes.map(attribute => attribute as Option);

        this.roomGroupService.getAll().subscribe((response: { data: RoomGroup[] }) => {
          response.data.forEach(item => {
            const a = this.resource.package_room_group.find(x => x.room_group_id === item.id) as PackageRoomGroup;
            if (!a) {
              this.resource.package_room_group.push(this.getRoomGroup(item));
            } else {
              a.name = item.name;
              a.selected = true;
            }
          });
        });
      }
    );
  }

  onImageDelete(event: Event) {
    const idx = _indexOf(this.resource.images, event, 'id');

    if (idx > -1) {
      this.resource.images.splice(idx, 1);
    }
  }

  onSuccess(event: Event) {
    const response = JSON.parse(event[0].xhr.response).data as Image;

    this.resource.images.push(response);
  }

  onAttributeChanges(options: Option[]) {
    this.resource.attributes = options.map(option => ({content: option.content, id: option.id} as Attribute));
  }

  onSubmit() {
    const resourceToPersist = JSON.parse(JSON.stringify(this.resource)) as Package;
    resourceToPersist.package_room_group = this.resource.package_room_group.filter(x => x.selected === true);

    if (this.resourceId) {
      this.service.update(this.resourceId, resourceToPersist).subscribe();
      return;
    }

    this.service.create(resourceToPersist).subscribe();
    return;
  }

  onPresentDateChange(dates) {
    this.resource.present_from = moment(dates[0]).format('YYYY-MM-DD');
    this.resource.present_to = moment(dates[1]).format('YYYY-MM-DD');
  }

  onAvailableDateChange(dates) {
    this.resource.available_from = moment(dates[0]).format('YYYY-MM-DD');
    this.resource.available_to = moment(dates[1]).format('YYYY-MM-DD');

    this.updateDaysBefore();
  }

  getDaysLength() {
    return moment(this.resource.available_to).diff(moment(this.resource.available_from), 'days');
  }

  private getRoomGroup(item: RoomGroup) {
    return {
      name: item.name,
      price: item.price,
      price_for_additional_person: item.price_for_additional_person,
      advance_price: item.advance_price,
      room_group_id: item.id,
      selected: false
    } as PackageRoomGroup;
  }

  updateDaysBefore() {
    this.resource.present_from = moment(this.resource.available_from)
      .subtract(this.daysPresentBeforeAvailable, 'days')
      .format('YYYY-MM-DD');

    if (moment(this.resource.present_from).diff(now(), 'days') < 0 && moment(this.resource.present_from).diff(now(), 'days') > 0) {
      this.resource.present_from = moment().format('YYYY-MM-DD');
    }

    this.resource.present_to = this.resource.available_to;
  }
}
