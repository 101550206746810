import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/services/resource.service';
import { PriceTable } from '../../shared/models/price-table';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { monthsDiff } from '../../shared/helpers';

interface DateRange {
  date_from: Date;
  date_to: Date;
}

@Injectable({
  providedIn: 'root'
})
export class PriceTableService extends ResourceService<PriceTable> {

  url = '/price-tables';

  private calendar: { [month: string]: string[] };
  private priceSubject = new Subject<number>();
  price$ = this.priceSubject.asObservable();
  private dateRangeSubject = new Subject<void>();
  dateRange$ = this.dateRangeSubject.asObservable();
  counter = 0;

  calendarDays(range: DateRange) {
    this.calendar = {};

    let firstDay = moment(range.date_from);
    let lastDay = moment(range.date_to);
    const diff = monthsDiff(firstDay.toDate(), lastDay.toDate());

    let currentMonth = firstDay.format('MMMM');
    let currentYear = firstDay.format('YYYY');

    let daysInMonth: number;
    let key: string;

    for (let i = 0; i < diff; i++) {
      firstDay = i === 0 ? moment(range.date_from)
        : moment(range.date_from).add(i, 'months').startOf('month');

      lastDay = i === (diff - 1) ? moment(range.date_to)
        : moment(firstDay).endOf('month');

      currentMonth = firstDay.format('MMMM');
      currentYear = firstDay.format('YYYY');

      key = `${currentMonth} ${currentYear}`;

      this.calendar[key] = [];

      daysInMonth = lastDay.diff(firstDay, 'days');

      for (let j = 0; j <= daysInMonth; j++) {
        this.calendar[key].push(firstDay.format('YYYY-MM-DD'));
        firstDay.add(1, 'days');
      }
    }

    return this.calendar;
  }

  toControls(): { [date: string]: number } {
    const controls = {};

    Object.keys(this.calendar).forEach(month => this.calendar[month].forEach(day => controls[day] = null));

    return controls;
  }

  priceChanged(price: number) {
    this.priceSubject.next(price);
  }

  dateRangeChanged() {
    this.dateRangeSubject.next();
  }

}


