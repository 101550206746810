import {Injectable} from '@angular/core';
import {ResourceService} from '../../shared/services/resource.service';
import {Settlement} from '../../shared/models/settlement';
import {Observable, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettlementService extends ResourceService<Settlement> {

  url = '/settlements';

  getSettlement(id: number): Observable<{ data: Settlement }> {
    return this.http.get<{ data: Settlement }>(`${this.config.api}${this.url}/${id}`)
      .pipe(
        catchError(() => of({ data: null })),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  getInvoice(notificationId: number): any {
    return this.http.get(`${this.config.api}${this.url}/${notificationId}/invoice`, {
      responseType: 'blob',
    })
      .pipe(
        map((res: any) => {
          return new Blob([res], {
            type: 'application/pdf'
          });
        })
      );
  }

  refreshInvoice(id: number): any {
    return this.http.post(`${this.config.api}${this.url}/${id}/refresh-invoice`, {})
      .pipe(
        catchError(() => of({ data: null })),
        finalize(() => this.loadingSubject.next(false))
      );
  }
}
