import {AgreementFormComponent} from './components/agreement/agreement-form/agreement-form.component';
import {AgreementListComponent} from './components/agreement/agreement-list/agreement-list.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LayoutComponent} from './components/layout/layout.component';
import {LoginComponent} from './components/login/login.component';
import {AuthGuard} from './guards/auth.guard';
import {GuestGuard} from './guards/guest.guard';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {RgtListComponent} from './components/room-group-type/rgt-list/rgt-list.component';
import {RgtFormComponent} from './components/room-group-type/rgt-form/rgt-form.component';
import {RgsListComponent} from './components/room-group-standard/rgs-list/rgs-list.component';
import {RgsFormComponent} from './components/room-group-standard/rgs-form/rgs-form.component';
import {UserListComponent} from './components/user/user-list/user-list.component';
import {UserFormComponent} from './components/user/user-form/user-form.component';
import {ClientListComponent} from './components/client/client-list/client-list.component';
import {ClientFormComponent} from './components/client/client-form/client-form.component';
import {PriceTableListComponent} from './components/price-table/price-table-list/price-table-list.component';
import {PriceTableFormComponent} from './components/price-table/price-table-form/price-table-form.component';
import {HotelListComponent} from './components/hotel/hotel-list/hotel-list.component';
import {HotelFormComponent} from './components/hotel/hotel-form/hotel-form.component';
import {ReservationFormComponent} from './components/reservation/reservation-form/reservation-form.component';
import {AttributeListComponent} from './components/attribute/attribute-list/attribute-list.component';
import {AttributeFormComponent} from './components/attribute/attribute-form/attribute-form.component';
import {RoomGroupListComponent} from './components/room-group/room-group-list/room-group-list.component';
import {RoomGroupFormComponent} from './components/room-group/room-group-form/room-group-form.component';
import {RoomGroupCalendarListComponent} from './components/room-group/room-group-calendar-list/room-group-calendar-list.component';
import {ProfileFormComponent} from './components/profile/profile-form/profile-form.component';
import {ConfigurationComponent} from './components/configuration/configuration.component';
import {ReservationCreateComponent} from './components/reservation/reservation-create/reservation-create.component';
import {ReservationConfirmationComponent} from './components/reservation/reservation-confirmation/reservation-confirmation.component';
import {SettlementListComponent} from './components/settlement/settlement-list/settlement-list.component';
import {SettlementDetailComponent} from './components/settlement/settlement-detail/settlement-detail.component';
import {BseSettelmentListComponent} from './components/bse-settlement/bse-settelment-list/bse-settelment-list.component';
import {BseSettelmentDetailComponent} from './components/bse-settlement/bse-settelment-detail/bse-settelment-detail.component';
import {ReservationComponent} from './components/reservation/reservation/reservation.component';
import {RoomsAvailabilityTableComponent} from './components/rooms-availability/rooms-availability-table/rooms-availability-table.component';
import {PackageListComponent} from './components/packages/package-list/package-list.component';
import {PackageFormComponent} from './components/packages/package-form/package-form.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent, canActivate: [GuestGuard]},
  {path: 'reset-password', component: ResetPasswordComponent, canActivate: [GuestGuard]},
  {path: 'reset-password/:hash', component: ResetPasswordComponent, canActivate: [GuestGuard]},
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {path: '', pathMatch: 'full', component: DashboardComponent},
      {
        path: 'room-group-types',
        children: [
          {
            path: '',
            component: RgtListComponent
          },
          {
            path: 'create',
            component: RgtFormComponent,
          },
          {
            path: ':id/edit',
            component: RgtFormComponent,
          },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['hotel_admin', 'hotel_manager']
        }
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            component: UserListComponent
          },
          {
            path: 'create',
            component: UserFormComponent,
          },
          {
            path: ':id/edit',
            component: UserFormComponent
          },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator', 'hotel_admin', 'hotel_manager', 'hotel_reception']
        }
      },
      {
        path: 'room-group-standards',
        children: [
          {
            path: '',
            component: RgsListComponent
          },
          {
            path: 'create',
            component: RgsFormComponent,
          },
          {
            path: ':id/edit',
            component: RgsFormComponent,
          },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['hotel_admin', 'hotel_manager']
        }
      },
      {
        path: 'clients',
        children: [
          {
            path: '',
            component: ClientListComponent
          },
          {
            path: 'create',
            component: ClientFormComponent,
          },
          {
            path: ':id/edit',
            component: ClientFormComponent
          },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator', 'hotel_admin', 'hotel_manager', 'hotel_reception']
        }
      },
      {
        path: 'price-tables',
        children: [
          {
            path: '',
            component: PriceTableListComponent
          },
          {
            path: 'create',
            component: PriceTableFormComponent,
          },
          {
            path: ':id/edit',
            component: PriceTableFormComponent
          },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['hotel_admin', 'hotel_manager']
        }
      },
      {
        path: 'hotels',
        children: [
          {
            path: '',
            component: HotelListComponent
          },
          {
            path: 'create',
            component: HotelFormComponent,
          },
          {
            path: ':id/edit',
            component: HotelFormComponent,
          }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator', 'hotel_admin']
        }
      },
      {
        path: 'reservations',
        children: [
          {
            path: '',
            component: ReservationComponent
          },
          {
            path: 'create',
            component: ReservationCreateComponent,
          },
          {
            path: 'create/confirmation',
            component: ReservationConfirmationComponent,
          },
          {
            path: ':id/edit',
            component: ReservationFormComponent,
          }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator', 'hotel_admin', 'hotel_manager', 'hotel_reception']
        }
      },
      {
        path: 'attributes',
        children: [
          {
            path: '',
            component: AttributeListComponent
          },
          {
            path: 'create',
            component: AttributeFormComponent,
          },
          {
            path: ':id/edit',
            component: AttributeFormComponent,
          }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator', 'hotel_admin', 'hotel_manager']
        }
      },
      {
        path: 'room-groups',
        children: [
          {
            path: '',
            component: RoomGroupListComponent
          },
          {
            path: 'create',
            component: RoomGroupFormComponent,
          },
          {
            path: ':id/edit',
            component: RoomGroupFormComponent,
          },
          {
            path: ':id/calendar',
            component: RoomGroupCalendarListComponent,
          }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator', 'hotel_admin', 'hotel_manager']
        }
      },
      {
        path: 'agreements',
        children: [
          {
            path: '',
            component: AgreementListComponent
          },
          {
            path: 'create',
            component: AgreementFormComponent,
          },
          {
            path: ':id/edit',
            component: AgreementFormComponent,
          }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['hotel_admin', 'hotel_manager']
        }
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            component: ProfileFormComponent,
          }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator', 'hotel_admin', 'hotel_manager', 'hotel_reception']
        }
      },
      {
        path: 'configuration',
        children: [
          {
            path: '',
            component: ConfigurationComponent
          },
          {
            path: ':id/edit',
            component: ConfigurationComponent,
          }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator', 'hotel_admin', 'hotel_manager']
        }
      },
      {
        path: 'rooms-availability',
        children: [
          {
            path: '',
            component: RoomsAvailabilityTableComponent
          },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['hotel_admin', 'hotel_manager']
        }
      },
      {
        path: 'settlements',
        children: [
          {
            path: '',
            component: SettlementListComponent
          },
          {
            path: ':id',
            component: SettlementDetailComponent,
          }
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['hotel_admin', 'hotel_admin', 'hotel_manager']
        }
      },
      {
        path: 'admin-settlements',
        children: [
          {
            path: '',
            component: BseSettelmentListComponent
          },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator']
        }
      },
      {
        path: 'packages',
        children: [
          {
            path: '',
            component: PackageListComponent
          },
          {
            path: 'create',
            component: PackageFormComponent
          },
          {
            path: ':id/edit',
            component: PackageFormComponent
          },
        ],
        canActivate: [AuthGuard],
        data: {
          roles: ['superadministrator', 'hotel_admin', 'hotel_manager']
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PanelRoutingModule {
}
