export class DashboardBrickStats {
  total_sales: number;
  sales_value: number;
  sales_amount: number;
  average_stay: number;
  sales_value_last_month: number;
  sales_amount_last_month: number;

  constructor() {
    this.total_sales = this.sales_value = this.sales_amount = this.average_stay = this.sales_value_last_month = this.sales_amount_last_month = null;
  }
}
