import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {Data} from '../../../../shared/DTOs/data-source.model';
import {ReservationService} from '../../../services/reservation.service';
import {MatDialog} from '@angular/material';
import {ReservationManageDialogComponent} from '../reservation-manage-dialog/reservation-manage-dialog.component';

@Component({
  selector: 'app-reservation-calendar',
  templateUrl: './reservation-calendar.component.html',
  styleUrls: ['./reservation-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReservationCalendarComponent implements OnInit {
  calendarPlugins = [dayGridPlugin, interactionPlugin];
  resourceReservation: Data[];

  header = {
    left: 'title',
    right: 'today,prev,next',
  };

  buttonText = {
    today:    'Dzisiaj',
    month:    'Miesięc',
    week:     'Tydzień',
    day:      'Dzień',
    list:     'Lista'
  };

  ngOnInit(): void {
  }

  constructor(
    private reservationService: ReservationService,
    private dialog: MatDialog
  ) {
    this.getReservations();
  }

  handleDateClick(arg) {
    this.dialog.open(ReservationManageDialogComponent, {
      data: {
        reservationId: arg.event.id,
      },
    });
  }

  getReservations() {
    this.reservationService.getReservationsForCalendar()
      .subscribe(response => {
        this.resourceReservation = response.data as Data[];
      });
  }
}
