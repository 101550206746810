import {Injectable} from '@angular/core';
import {ResourceService} from '../../shared/services/resource.service';
import {Settlement} from '../../shared/models/settlement';
import {catchError, finalize} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminSettlementService extends ResourceService<Settlement> {

  url = '/admin-settlements';

  markSettled(id: number): any {
    return this.http.post(`${this.config.api}${this.url}/${id}/mark-settled`, {})
      .pipe(
        catchError(() => of({ data: null })),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  markInvoiceSent(id: number): any {
    return this.http.post(`${this.config.api}${this.url}/${id}/mark-invoice-sent`, {})
      .pipe(
        catchError(() => of({ data: null })),
        finalize(() => this.loadingSubject.next(false))
      );
  }
}
