import { OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '../services/layout.service';
import { IResourceService } from '../DTOs/data-source.model';
import { AppConfig, APP_CONFIG } from '../../app.config';
import { AuthService } from '../services/auth.service';

export abstract class FormTemplate<T> implements OnInit {
  protected creating = true;
  protected resourceId: number;
  protected resource: T;
  protected abstract resourceName: string;

  protected route: ActivatedRoute;
  protected router: Router;
  protected layoutService: LayoutService;
  protected authService: AuthService;
  protected config: AppConfig;

  service: IResourceService<T>;

  constructor(injector: Injector) {
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.layoutService = injector.get(LayoutService);
    this.config = injector.get(APP_CONFIG);
    this.authService = injector.get(AuthService);
  }

  ngOnInit() {
    this.resourceId = +this.route.snapshot.paramMap.get('id');

    if (this.resourceId) {
      this.layoutService.setTitle(`Edytuj ${this.resourceName}`);

      this.creating = false;
      this.getItem(this.resourceId);
    } else {
      this.layoutService.setTitle(`Stwórz ${this.resourceName}`);

      this.creating = true;
    }
  }

  getItem(id: number) {
    this.service.get(id)
      .subscribe(resource => this.resource = resource.data);
  }

  onSubmit() {
    if (this.resourceId) {
      this.service.update(this.resourceId, this.resource)
        .subscribe();

      return;
    }

    this.service.create(this.resource)
      .subscribe();
  }
}
