import { Component, Injector, OnInit } from '@angular/core';
import { FormTemplate } from '../../../../shared/components/form.template';
import { PriceTable } from '../../../../shared/models/price-table';
import { PriceTableService } from '../../../services/price-table.service';
import * as moment from 'moment';

@Component({
  selector: 'app-price-table-form',
  templateUrl: './price-table-form.component.html',
  styleUrls: ['./price-table-form.component.scss']
})
export class PriceTableFormComponent extends FormTemplate<PriceTable> implements OnInit {

  resourceName = 'plan cenowy';
  resource = new PriceTable();

  today = moment().toDate();
  lastDay = moment().add(1, 'years').toDate();
  dateRange = {
    date_from: moment().toDate(),
    date_to: moment().add(2, 'months').endOf('month').toDate()
  };
  days = this.priceTableService.calendarDays(this.dateRange);

  constructor(
    private priceTableService: PriceTableService,
    private injector: Injector
  ) {
    super(injector);
    this.service = this.priceTableService;
  }

  ngOnInit() {
    super.ngOnInit();

    this.updateResourceRange();
  }

  getItem(id: number) {
    this.service.get(id)
      .subscribe(resource => {
        this.resource = resource.data;
        this.resource.price = this.resource.price / 100;
        this.onDateRangeChange([this.resource.start_date, this.resource.end_date]);
      });
  }

  onSubmit() {
    this.onlyFilledDates();

    this.resource.price = this.resource.price * 100;
    super.onSubmit();
    this.resource.price = this.resource.price / 100;
    return;
  }

  priceChanged(price: number) {
    this.priceTableService.priceChanged(price);
  }

  onDateRangeChange(dates) {
    this.dateRange = {
      date_from: dates[0],
      date_to: dates[1],
    };

    this.updateResourceRange();

    this.days = this.priceTableService.calendarDays(this.dateRange);
    this.updateResourceDates();
    this.priceTableService.priceChanged(this.resource.price);

    this.priceTableService.dateRangeChanged();
  }

  get months() {
    return Object.keys(this.days);
  }

  private onlyFilledDates() {
    this.resource.days = Object.keys(this.resource.days)
      .filter(day => !!this.resource.days[day])
      .reduce((obj, key) => {
        obj[key] = this.resource.days[key];
        return obj;
      }, {});
  }

  private updateResourceDates() {
    this.resource.days = Object.assign(this.priceTableService.toControls(), this.resource.days);
  }

  private updateResourceRange() {
    this.resource.start_date = moment(this.dateRange.date_from).format('YYYY-MM-DD');
    this.resource.end_date = moment(this.dateRange.date_to).format('YYYY-MM-DD');
  }
}
