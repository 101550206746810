import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/services/resource.service';
import { Client } from '../../shared/models/client';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends ResourceService<Client> {

  url = '/clients';

}
