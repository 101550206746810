import { Injectable } from '@angular/core';
import { Attribute } from '../../shared/models/attribute';
import { ResourceService } from '../../shared/services/resource.service';

@Injectable({
  providedIn: 'root'
})
export class AttributeService extends ResourceService<Attribute> {

  url = '/attributes';

  listAll(resourceId?: number) {
    return this.http.get<{ data: Attribute[] }>(`${this.config.api}${this.url}/list-all`, resourceId ? {
      headers: {
        'Hotel': resourceId.toString()
      }
    } : {});
  }

}
