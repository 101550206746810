export class Agreement {
  id: number;
  name: string;
  hotel_id: number;
  description: string;
  required: boolean;

  constructor() {
    this.id = this.name = this.description = this.hotel_id = null;
    this.required = false;
  }
}
