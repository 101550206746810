import { Component, Injector, OnInit } from '@angular/core';
import { Client } from '../../../../shared/models/client';
import { FormTemplate } from '../../../../shared/components/form.template';
import { ClientService } from '../../../services/client.service';
import { User } from '../../../../shared/models/user';
import { Address } from '../../../../shared/models/address';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})

export class ClientFormComponent extends FormTemplate<Client> implements OnInit {

  resourceName = 'klienta';
  resource = new Client();

  constructor(
    private clientService: ClientService,
    private injector: Injector,
  ) {
    super(injector);
    this.service = this.clientService;
    this.resource.user = new User();
    this.resource.address = new Address();
  }
}
