import { Hotel } from './hotel';

export class Attribute {
  id: number;
  content: string;
  icon: string;
  owner: Hotel;

  constructor() {
    this.id = this.icon = this.content = null;
    this.owner = new Hotel();
  }
}
