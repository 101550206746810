import { AuthService } from '../../../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { BsEngineService } from '../../../../shared/services/bsEngine.service';
import { RoomGroup } from '../../../../shared/models/room-group';
import { Router } from '@angular/router';
import { Booking } from '../../../../shared/models/booking';
import * as moment from 'moment';
import { LocalStorage } from '../../../../shared/services/local-storage.service';

@Component({
  selector: 'app-reservation-create',
  templateUrl: './reservation-create.component.html',
  styleUrls: ['./reservation-create.component.scss']
})
export class ReservationCreateComponent implements OnInit {
  rooms: RoomGroup[] = [];
  booking: Booking;
  today: Date;
  lastDay: Date;
  bsValue: Date[];
  maxChildrenCount: number;

  constructor(
    private bsEngine: BsEngineService,
    private authService: AuthService,
    private localStorage: LocalStorage,
    private router: Router,
  ) {

  }

  ngOnInit() {
    this.booking = this.bsEngine.getBooking;
    this.booking.date_from = moment().format('YYYY-MM-DD');
    this.booking.date_to = moment().add(1, 'days').format('YYYY-MM-DD');
    this.booking.adults = 2;
    this.booking.children = {
      count: 0,
      age: []
    };
    this.today = moment().toDate();
    this.lastDay = moment().add(1, 'year').toDate();
    this.bsValue = [moment(this.booking.date_from).toDate(), moment(this.booking.date_to).toDate()];
    this.checkRooms();

    this.setMaxChildrenCount();
  }

  checkRooms() {
    this.bsEngine.checkRooms().subscribe(response => {
      this.rooms = response.data;
    });
  }

  goToConfirmation(room: RoomGroup) {
    this.bsEngine.setRoom = room;

    this.router.navigate(['reservations/create/confirmation']);
  }

  onDateRangeChange(event) {
    if (event !== null) {
      this.booking.date_from = moment(event[0]).format('YYYY-MM-DD');
      this.booking.date_to = moment(event[1]).format('YYYY-MM-DD');
      this.checkRooms();
    }
  }

  onChildrenChange(event: Event) {
    const value = Math.min(parseInt((event.target as HTMLInputElement).value, 10), this.maxChildrenCount);

    this.booking.children.age = Array(value).fill('c').map((x, i) => x);
  }

  private setMaxChildrenCount() {
    const hotel_id = parseInt(atob(this.localStorage.getItem('bse_hotel')), 10);

    this.maxChildrenCount = this.authService.getUser().hotels.filter(hotel => hotel.id === hotel_id)[0].configuration.children_count;
  }
}
