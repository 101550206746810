import {Injectable} from '@angular/core';
import {ResourceService} from '../../shared/services/resource.service';
import {Package} from '../../shared/models/package';

@Injectable({
  providedIn: 'root'
})
export class PackageService extends ResourceService<Package> {
  url = '/packages';
}
