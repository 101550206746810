import {Component, Injector} from '@angular/core';
import {ListTemplate} from '../../../../shared/components/list.template';
import {User} from '../../../../shared/models/user';
import {UserService} from '../../../services/user.service';
import {AuthLoginAsService} from '../../../../shared/services/authLoginAs.service';
import {AuthService} from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends ListTemplate<User> {

  displayedColumns = ['first_name', 'last_name', 'roles', 'created_at', 'actions'];
  resourceName = 'Użytkownicy';

  constructor(
    public userService: UserService,
    private injector: Injector,
    protected authLoginAsService: AuthLoginAsService,
    protected authService: AuthService
  ) {
    super(injector);

    this.service = this.userService;
  }

  mapRoles(user: User) {
    return user.roles.map(role => role.display_name).join(', ');
  }

  loginAs(id: number) {
    this.authLoginAsService.loginAs(id);
  }

  get logged() {
    return !this.authLoginAsService.logged;
  }

  isLoginAsAble(user: User) {
    if (this.authService.getUser().hasRole('superadministrator')) {
      return true;
    }

    if (this.authService.getUser().hasRole('hotel_admin')) {
      const userRoles = user.roles.map(role => role.name);
      console.log(user.first_name, userRoles)
      return userRoles.indexOf('hotel_manager') > -1 || userRoles.indexOf('hotel_reception') > -1;
    }

    return false;
  }
}
