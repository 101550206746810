import { MessageService } from './../../shared/services/message.service';
import { APP_CONFIG, AppConfig } from './../../app.config';
import { DashboardBrickStats } from '../../shared/models/stats/dashboard-brick-stats';
import { DashboardSalesStats } from '../../shared/models/stats/dashboard-sales-stats';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ResourceResponse } from '../../shared/DTOs/data-source.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardStatsService {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.headers = new HttpHeaders();
  }

  getDashboardBrickStats() {
    this.loadingSubject.next(true);
    return this.http.get<{ data: DashboardBrickStats }>(`${this.config.api}/stats/dashboard/brick`, { headers: this.headers})
      .pipe(
        catchError(() => of({ data: new DashboardBrickStats() })),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  getDashboardSalesStats() {
    this.loadingSubject.next(true);
    return this.http.get<{ data: DashboardSalesStats }>(`${this.config.api}/stats/dashboard/sales`, { headers: this.headers})
      .pipe(
        catchError(() => of({ data: new DashboardSalesStats() })),
        finalize(() => this.loadingSubject.next(false))
      );
  }

}
