import { Component, Injector } from '@angular/core';
import { ListTemplate } from '../../../../shared/components/list.template';
import { AttributeService } from '../../../services/attribute.service';
import { Attribute } from '../../../../shared/models/attribute';

@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss']
})
export class AttributeListComponent extends ListTemplate<Attribute> {

  displayedColumns = ['icon', 'content', 'actions'];
  resourceName = 'Udogodnienia';

  constructor(
    public attributeService: AttributeService,
    private injector: Injector,
  ) {
    super(injector);

    this.service = this.attributeService;
  }

}
