import { Injectable } from '@angular/core';
import { Agreement } from './../../shared/models/agreement';
import { ResourceService } from '../../shared/services/resource.service';

@Injectable({
  providedIn: 'root'
})
export class AgreementService extends ResourceService<Agreement> {

  url = '/agreements';

}
