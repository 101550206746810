import {Component, Inject, OnInit} from '@angular/core';
import {Configuration} from '../../../shared/models/configuration';
import {EnumItem} from '../../../shared/models/app';
import {ConfigurationService} from '../../services/configuration.service';
import {LayoutService} from '../../../shared/services/layout.service';
import {APP_CONFIG, AppConfig} from '../../../app.config';
import {MatDialog} from '@angular/material';
import {CopyInputService} from '../../../shared/services/copy-input.service';
import {toggle} from '../../../widget/animations';
import {EnumService} from '../../../shared/services/enum.service';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  animations: [toggle],
})
export class ConfigurationComponent implements OnInit {
  resourceName = 'Ustawienia hotelu';
  resource = new Configuration();
  primaryColor: string;
  advancePayments: Observable<EnumItem[]> = of([]);
  editor = {
    editable: true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
      ['fontSize', 'color'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['orderedList', 'unorderedList', 'paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine'],
    ]
  };

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    public service: ConfigurationService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    public copy: CopyInputService,
    private enumService: EnumService,
  ) {
  }

  ngOnInit() {
    this.layoutService.setTitle(`Edytuj ${this.resourceName}`);
    this.advancePayments = this.enumService.advancePayments();

    this.getConfiguration();

    this.primaryColor = this.resource.primary_color;
  }

  getConfiguration() {
    this.service.get().subscribe(response => this.resource = response.data);
  }

  onSubmit() {
    this.resource.minimum_advance_payment *= 100;
    this.service.update(this.resource).subscribe();
    this.resource.minimum_advance_payment /= 100;
  }
}
