import { Component, OnInit } from '@angular/core';
import { User } from '../../../../shared/models/user';
import { AuthService } from '../../../../shared/services/auth.service';
import { ProfileService } from '../../../services/profile.service';
import { MessageService } from '../../../../shared/services/message.service';
import {toggleFade} from '../../../../widget/animations';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  animations: [
    toggleFade
  ]
})
export class ProfileFormComponent implements OnInit {

  resource: User;
  passwordChange = false;

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.resource = this.authService.user;
  }

  onSubmit() {
    this.profileService.update(this.resource).subscribe(
      (response) => {
        this.messageService.toast(response.message);
        this.authService.updateUser();
      }
    );
  }

  passwordToggle() {
    this.passwordChange = !this.passwordChange;
  }
}
