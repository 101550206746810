import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../shared/models/user';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { Observable } from 'rxjs';
import { Response } from '../../shared/models/response';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private httpClient: HttpClient,
              @Inject(APP_CONFIG) protected config: AppConfig
  ) {
  }

  public update(resource: User): Observable<Response> {
    return this.httpClient.post<Response>(`${this.config.api}/me`, resource);
  }
}
