import {Component, Injector, OnInit} from '@angular/core';
import {SettlementService} from '../../../services/settlement.service';
import {Settlement} from '../../../../shared/models/settlement';
import {Reservation} from '../../../../shared/models/reservation';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutService} from '../../../../shared/services/layout.service';
import {APP_CONFIG, AppConfig} from '../../../../app.config';
import {AuthService} from '../../../../shared/services/auth.service';
import {LocalStorage} from '../../../../shared/services/local-storage.service';

@Component({
  selector: 'app-settlement-detail',
  templateUrl: './settlement-detail.component.html',
  styleUrls: ['./settlement-detail.component.scss']
})
export class SettlementDetailComponent implements OnInit {

  settlement: Settlement;
  protected resourceId: number;
  protected resource: Settlement;

  protected route: ActivatedRoute;
  protected router: Router;
  protected layoutService: LayoutService;
  authService: AuthService;
  protected config: AppConfig;
  currentHotel;

  constructor(
    private settlementService: SettlementService,
    private localStorage: LocalStorage,
    injector: Injector
  ) {
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.layoutService = injector.get(LayoutService);
    this.config = injector.get(APP_CONFIG);
    this.authService = injector.get(AuthService);
    this.currentHotel = this.localStorage.getItem('bse_hotel');
  }

  displayedColumns: string[] = ['item', 'created', 'payment_method', 'cost', 'commission'];
  transactions: Reservation[];

  ngOnInit() {
    this.resourceId = +this.route.snapshot.paramMap.get('id');

    this.layoutService.setTitle(`Rozliczenie nr ${this.resourceId}`);

    this.getSettlement();
  }

  getSettlement() {
    this.settlementService.getSettlement(this.resourceId).subscribe((response) => {
      this.settlement = response.data;
      this.transactions = response.data.reservations_snap;
    });
  }

  getTotal() {
    return this.transactions.map(t => t.price).reduce((acc, value) => acc + value / 100, 0);
  }

  getTotalCommission() {
    return this.transactions.map(t => t.commission).reduce((acc, value) => acc + value / 100, 0);
  }

  getAbsolutePrice(total: number) {
    return Math.abs(total);
  }

  open() {
    this.settlementService.getInvoice(this.settlement.id).subscribe((response) => {
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL, '_blank');
    });
  }

  refreshInvoice() {
    this.settlementService.refreshInvoice(this.settlement.id).subscribe();
  }

}
