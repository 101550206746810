import { Component, OnInit, ViewChild } from '@angular/core';
import { BsEngineService } from '../../../../shared/services/bsEngine.service';
import { Router } from '@angular/router';
import { Client } from '../../../../shared/models/client';
import { RoomGroup } from '../../../../shared/models/room-group';
import { NgForm } from '@angular/forms';
import { Booking } from '../../../../shared/models/booking';
import { MessageService } from '../../../../shared/services/message.service';

@Component({
  selector: 'app-reservation-confirmation',
  templateUrl: './reservation-confirmation.component.html',
  styleUrls: ['./reservation-confirmation.component.scss']
})
export class ReservationConfirmationComponent implements OnInit {
  @ViewChild('confirmationForm', { static: true }) confirmationForm: NgForm;

  public client: Client;
  public booking: Booking;
  public room: RoomGroup;

  constructor(
    private bsEngineService: BsEngineService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.client = this.bsEngineService.getClient;
    this.room = this.bsEngineService.getRoom;
    this.booking = this.bsEngineService.getBooking;
  }

  ngOnInit() {
    if (this.bsEngineService.getRoom.id === null) {
      this.router.navigate(['/reservations/create']);
    }
  }

  onSubmit() {
    this.bsEngineService.book().subscribe( response => {
      this.messageService.toast(response.message);
      this.router.navigate(['/reservations']);
    });
  }

}
