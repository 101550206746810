import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ReservationManageDialogData} from '../../../../shared/models/dialogs';
import {ReservationService} from '../../../services/reservation.service';
import {Reservation} from '../../../../shared/models/reservation';
import {EnumService} from '../../../../shared/services/enum.service';
import {EnumItem} from '../../../../shared/models/app';
import {toggle} from '../../../../widget/animations';

@Component({
  selector: 'app-reservation-manage-dialog',
  templateUrl: './reservation-manage-dialog.component.html',
  styleUrls: ['./reservation-manage-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    toggle
  ]
})
export class ReservationManageDialogComponent implements OnInit {
  reservation = new Reservation();
  reservations_statuses: EnumItem[] = [];
  changeStatus = false;

  constructor(
    public dialogRef: MatDialogRef<ReservationManageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReservationManageDialogData,
    private reservationService: ReservationService,
    private enumService: EnumService) {
  }

  ngOnInit(): void {
    this.getReservation();

    this.enumService.reservationStatuses()
      .subscribe(statuses => this.reservations_statuses = statuses);
  }

  onSubmit() {
    this.reservation.price *= 100;
    this.reservation.advance_payment_price *= 100;
    this.reservationService.update(this.reservation.id, this.reservation).subscribe(() => {
      this.getReservation();
      this.changeStatus = false;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getReservation() {
    this.reservationService.get(this.data.reservationId).subscribe(
      response => {
        this.reservation = response.data as Reservation;
        this.reservation.price /= 100;
        this.reservation.advance_payment_price /= 100;
        this.reservation.to_pay /= 100;
      }
    );
  }
}
