import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/services/resource.service';
import { RoomGroupType } from '../../shared/models/room-group-type';

@Injectable({
  providedIn: 'root'
})
export class RoomGroupTypeService extends ResourceService<RoomGroupType> {

  url = '/room-group-types';

}
