import { BehaviorSubject, of, Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { MessageService } from '../../shared/services/message.service';
import { DATA_PLACEHOLDER, ResourceResponse, IResourceService, DataSourceModel } from '../../shared/DTOs/data-source.model';
import { ActivatedRoute, Router } from '@angular/router';

export abstract class ResourceService<T> implements IResourceService<T> {

  public abstract url: string;
  public headers: HttpHeaders;
  protected loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  protected filterSubject = new BehaviorSubject<boolean>(false);
  public filter$ = this.filterSubject.asObservable();

  constructor(
    protected http: HttpClient,
    protected messageService: MessageService,
    protected route: ActivatedRoute,
    protected router: Router,
    @Inject(APP_CONFIG) protected config: AppConfig
  ) {
    this.headers = new HttpHeaders();
  }

  getAll(page = 1, filters: { [fieldName: string]: any } = {}): Observable<{ data: T[] } | DataSourceModel> {
    const isFiltered = Object.values(filters).filter(item => !!item).length;

    this.loadingSubject.next(true);

    if (isFiltered) {
      this.filterSubject.next(true);
    } else {
      this.filterSubject.next(false);
    }

    return this.http.get<{ data: T[] }>(`${this.config.api}${this.url}`, {
      params: Object.assign({
        page: page.toString()
      }, filters)
    }).pipe(
      catchError(() => of(DATA_PLACEHOLDER)),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  get(id: number): Observable<{ data: T }> {
    this.loadingSubject.next(true);

    return this.http.get<{ data: T }>(`${this.config.api}${this.url}/${id}`)
      .pipe(
        catchError(() => of({ data: null })),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  delete(id: number): Observable<ResourceResponse | void> {
    this.loadingSubject.next(true);

    return this.http.delete<ResourceResponse>(`${this.config.api}${this.url}/${id}`, {
      headers: this.headers
    })
      .pipe(
        map(response => this.messageService.toast(response.message)),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  update(id: number, resource: T): Observable<ResourceResponse | void> {
    this.loadingSubject.next(true);

    return this.http.patch<ResourceResponse>(`${this.config.api}${this.url}/${id}`, resource, {
      headers: this.headers
    })
      .pipe(
        map(response => this.messageService.toast(response.message)),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  create(resource: T): Observable<ResourceResponse | void> {
    this.loadingSubject.next(true);

    return this.http.post<ResourceResponse>(`${this.config.api}${this.url}`, resource, {
      headers: this.headers
    })
      .pipe(
        map(response => {
          if (response.status === 0) {
            this.router.navigate(response.data ? [`${this.url}/${response.data}/edit`] : [`${this.url}`]);
          }
          this.messageService.toast(response.message);
        }),
        finalize(() => this.loadingSubject.next(false))
      );
  }

}
