import {Component, Injector, OnInit} from '@angular/core';
import {FormTemplate} from '../../../../shared/components/form.template';
import {Reservation} from '../../../../shared/models/reservation';
import {ReservationService} from '../../../services/reservation.service';
import {EnumItem} from '../../../../shared/models/app';
import {EnumService} from '../../../../shared/services/enum.service';

@Component({
  selector: 'app-reservation-form',
  templateUrl: './reservation-form.component.html',
  styleUrls: ['./reservation-form.component.scss']
})
export class ReservationFormComponent extends FormTemplate<Reservation> implements OnInit {

  resourceName = 'rezerwacje';
  resource = new Reservation();
  reservations_statuses: EnumItem[] = [];

  constructor(
    private reservationService: ReservationService,
    private injector: Injector,
    private enumService: EnumService
  ) {
    super(injector);
    this.service = this.reservationService;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.enumService.reservationStatuses()
      .subscribe(statuses => this.reservations_statuses = statuses);
  }

  getItem(id: number) {
    this.service.get(id)
      .subscribe(resource => {
        this.resource = resource.data;
        this.resource.price = this.resource.price / 100;
      });
  }

  onSubmit(): undefined {
    this.resource.price = this.resource.price * 100;
    super.onSubmit();
    this.resource.price = this.resource.price / 100;
    return;
  }
}
