import {Component, Injector, OnInit} from '@angular/core';
import {ListTemplate} from '../../../../shared/components/list.template';
import {Reservation} from '../../../../shared/models/reservation';
import {Data} from '../../../../shared/DTOs/data-source.model';
import {ReservationService} from '../../../services/reservation.service';
import {LocalStorage} from '../../../../shared/services/local-storage.service';

@Component({
  selector: 'app-reservations-pending',
  templateUrl: './reservations-pending.component.html',
  styleUrls: ['./reservations-pending.component.scss']
})
export class ReservationsPendingComponent extends ListTemplate<Reservation> {

  resourceName = 'Pulpit';

  private localStorage: LocalStorage;
  readonly currHotel;

  resourceReservation: Data[];

  displayedColumns = ['name', 'stay', 'price', 'created_at', 'actions'];

  constructor(
    private injector: Injector,
    public reservationService: ReservationService,
  ) {
    super(injector);

    this.localStorage = injector.get(LocalStorage);
    this.currHotel = this.localStorage.getItem('bse_hotel');
    this.service = this.reservationService;

    this.getPendingReservations();
  }

  getPendingReservations() {
    if (this.currHotel) {
      this.reservationService.getPending()
        .subscribe(response => {
          this.resourceReservation = response.data as Data[];
        });
    }
  }
}
