import { BehaviorSubject, Subscription } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { AppService } from '../services/app.service';
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Router, UrlSegment } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MessageService } from '../services/message.service';
import { AppConfig, APP_CONFIG } from '../../app.config';
import { LocalStorage } from '../services/local-storage.service';

export class LayoutTemplate implements OnInit, OnDestroy {

  sidebarOpened = true;
  isHandset$ = new BehaviorSubject(false);
  title = '';
  screenWidth: number;
  subscriptions: {[name: string]: Subscription} = {};

  protected breakpointObserver: BreakpointObserver;
  protected layoutService: LayoutService;
  protected appService: AppService;
  public authService: AuthService;
  protected messageService: MessageService;
  protected localStorage: LocalStorage;
  protected router: Router;
  protected config: AppConfig;
  protected url: UrlSegment;

  constructor(
    injector: Injector
  ) {
    this.breakpointObserver = injector.get(BreakpointObserver);
    this.layoutService = injector.get(LayoutService);
    this.appService = injector.get(AppService);
    this.authService = injector.get(AuthService);
    this.messageService = injector.get(MessageService);
    this.localStorage = injector.get(LocalStorage);
    this.config = injector.get(APP_CONFIG);
    this.router = injector.get(Router);

    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.TabletPortrait,
      Breakpoints.WebPortrait,
    ]).subscribe(result => this.isHandset$.next(result.matches));
  }

  ngOnInit() {
    this.subscriptions.layout = this.layoutService.title$.subscribe(title => this.title = title);
    this.subscriptions.isHandset = this.isHandset$.subscribe(result => this.sidebarOpened = !result);
    this.subscriptions.authLoginAsService = this.authService.authLoginAsService.loginAsObserver.subscribe(loginAsObserver => {
      this.authService.updateUser();
    });
    if (this.authService.isValid()) {
      this.appService.updateMenu(this.authService.getUser().menu);
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach(key => this.subscriptions[key].unsubscribe());
  }

  logout() {
    this.authService.logout().subscribe((response) => {
      this.router.navigate(['/login']);

      this.messageService.toast(response.message);
    });
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }
}
