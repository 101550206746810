import {AgreementService} from './services/agreement.service';
import {AgreementFormComponent} from './components/agreement/agreement-form/agreement-form.component';
import {AgreementListComponent} from './components/agreement/agreement-list/agreement-list.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PanelRoutingModule} from './panel-routing.module';
import {MaterialModule} from '../shared/modules/material.module';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LayoutComponent} from './components/layout/layout.component';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {RgtListComponent} from './components/room-group-type/rgt-list/rgt-list.component';
import {RgtFormComponent} from './components/room-group-type/rgt-form/rgt-form.component';
import {UserListComponent} from './components/user/user-list/user-list.component';
import {UserFormComponent} from './components/user/user-form/user-form.component';
import {RgsListComponent} from './components/room-group-standard/rgs-list/rgs-list.component';
import {RgsFormComponent} from './components/room-group-standard/rgs-form/rgs-form.component';
import {RoomGroupTypeService} from './services/room-group-type.service';
import {ClientFormComponent} from './components/client/client-form/client-form.component';
import {ClientListComponent} from './components/client/client-list/client-list.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HotelInterceptor} from './interceptors/hotel.interceptor';
import {PriceTableListComponent} from './components/price-table/price-table-list/price-table-list.component';
import {PriceTableFormComponent} from './components/price-table/price-table-form/price-table-form.component';
import {UserService} from './services/user.service';
import {RoomGroupStandardService} from './services/room-group-standard.service';
import {HotelListComponent} from './components/hotel/hotel-list/hotel-list.component';
import {HotelFormComponent} from './components/hotel/hotel-form/hotel-form.component';
import {HotelService} from './services/hotel.service';
import {ReservationFormComponent} from './components/reservation/reservation-form/reservation-form.component';
import {ReservationListComponent} from './components/reservation/reservation-list/reservation-list.component';
import {AttributeService} from './services/attribute.service';
import {PriceTableService} from './services/price-table.service';
import {AttributeListComponent} from './components/attribute/attribute-list/attribute-list.component';
import {AttributeFormComponent} from './components/attribute/attribute-form/attribute-form.component';
import {TypeaheadChipsComponent} from './components/typeahead-chips/typeahead-chips.component';
import {DzUploaderComponent} from './components/dz-uploader/dz-uploader.component';
import {ReservationService} from './services/reservation.service';
import {DzUploaderService} from './services/dz-uploader.service';
import {RoomGroupService} from './services/room-group.service';
import {RoomGroupListComponent} from './components/room-group/room-group-list/room-group-list.component';
import {RoomGroupFormComponent} from './components/room-group/room-group-form/room-group-form.component';
import {ErrorHandlingInterceptor} from '../shared/interceptors/error-handling.interceptor';
import {AuthInterceptor} from '../shared/interceptors/auth.interceptor';
import {ProfileFormComponent} from './components/profile/profile-form/profile-form.component';
import {ProfileService} from './services/profile.service';
import {ConfigurationComponent} from './components/configuration/configuration.component';
import {ConfigurationService} from './services/configuration.service';
import {ReservationCreateComponent} from './components/reservation/reservation-create/reservation-create.component';
import {BsEngineService} from '../shared/services/bsEngine.service';
import {ReservationConfirmationComponent} from './components/reservation/reservation-confirmation/reservation-confirmation.component';
import {MonthTableComponent} from './components/price-table/month-table/month-table.component';
import {BsDatepickerModule, defineLocale, plLocale} from 'ngx-bootstrap';
import {NgxEditorModule} from 'ngx-editor';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IconPickerComponent} from './components/icon-picker/icon-picker.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DragToSelectModule} from 'ngx-drag-to-select';
import {ChartsModule} from 'ng2-charts';
import {ReservationsPendingComponent} from './components/dashboard/reservations-pending/reservations-pending.component';
import {RoomGroupCalendarListComponent} from './components/room-group/room-group-calendar-list/room-group-calendar-list.component';
import {ReservationManageDialogComponent} from './components/reservation/reservation-manage-dialog/reservation-manage-dialog.component';
import {SettlementListComponent} from './components/settlement/settlement-list/settlement-list.component';
import {SettlementDetailComponent} from './components/settlement/settlement-detail/settlement-detail.component';
import {BseSettelmentListComponent} from './components/bse-settlement/bse-settelment-list/bse-settelment-list.component';
import {BseSettelmentDetailComponent} from './components/bse-settlement/bse-settelment-detail/bse-settelment-detail.component';
import {ReservationComponent} from './components/reservation/reservation/reservation.component';
import {ReservationCalendarComponent} from './components/reservation/reservation-calendar/reservation-calendar.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {RoomsAvailabilityTableComponent} from './components/rooms-availability/rooms-availability-table/rooms-availability-table.component';
import {PhonesToNotifyComponent} from './components/configuration/phones-to-notify/phones-to-notify.component';
import {PackageListComponent} from './components/packages/package-list/package-list.component';
import {PackageFormComponent} from './components/packages/package-form/package-form.component';

defineLocale('pl', plLocale);

@NgModule({
  entryComponents: [
    ReservationManageDialogComponent,
  ],
  declarations: [
    DashboardComponent,
    LayoutComponent,

    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    TypeaheadChipsComponent,
    DzUploaderComponent,

    RgtListComponent,
    RgtFormComponent,

    UserListComponent,
    UserFormComponent,

    RgsListComponent,
    RgsFormComponent,

    ClientFormComponent,
    ClientListComponent,

    PriceTableListComponent,
    PriceTableFormComponent,
    MonthTableComponent,

    HotelListComponent,
    HotelFormComponent,

    ReservationFormComponent,
    ReservationListComponent,

    AttributeListComponent,
    AttributeFormComponent,

    RoomGroupListComponent,
    RoomGroupFormComponent,

    AgreementListComponent,
    AgreementFormComponent,

    ProfileFormComponent,
    ConfigurationComponent,
    ReservationCreateComponent,
    ReservationConfirmationComponent,
    IconPickerComponent,
    ReservationsPendingComponent,
    RoomGroupCalendarListComponent,
    SettlementListComponent,
    SettlementDetailComponent,
    BseSettelmentListComponent,
    BseSettelmentDetailComponent,
    ReservationCalendarComponent,
    ReservationManageDialogComponent,
    ReservationComponent,
    RoomsAvailabilityTableComponent,
    PhonesToNotifyComponent,
    PackageListComponent,
    PackageFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PanelRoutingModule,
    BsDatepickerModule.forRoot(),
    NgxEditorModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    DragDropModule,
    DragToSelectModule.forRoot(),
    ChartsModule,
    FullCalendarModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HotelInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    RoomGroupTypeService,
    RoomGroupStandardService,
    UserService,
    HotelService,
    PriceTableService,
    AttributeService,
    ReservationService,
    DzUploaderService,
    RoomGroupService,
    ProfileService,
    ConfigurationService,
    BsEngineService,
    AgreementService,
  ]
})
export class PanelModule {
}
