import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/services/resource.service';
import { RoomGroupStandard } from '../../shared/models/room-group-standard';

@Injectable({
  providedIn: 'root'
})
export class RoomGroupStandardService extends ResourceService<RoomGroupStandard> {

  url = '/room-group-standards';

}
