export class UserRegister {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirmation: string;
    phone: string;

    constructor() {
        this.first_name = this.last_name = this.email = this.password = this.password_confirmation = this.phone = '';
    }
}