import { Component, Injector } from '@angular/core';
import { ListTemplate } from '../../../../shared/components/list.template';
import { Client } from '../../../../shared/models/client';
import { ClientService } from '../../../services/client.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent extends ListTemplate<Client> {

  displayedColumns = ['id', 'first_name', 'last_name', 'created_at', 'actions'];
  resourceName = 'Klienci';

  constructor(
    public clientService: ClientService,
    private injector: Injector,
  ) {
    super(injector);

    this.service = this.clientService;
  }

}
