import { Observable } from 'rxjs';

export interface Data {
  [name: string]: any;
}

interface Meta {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

interface Links {
  first: string;
  last: string;
  next: string;
  prev: string;
}

export interface DataSourceModel {
  data: Data[] | string;
  links: Links;
  meta: Meta;
}

export const DATA_PLACEHOLDER: DataSourceModel = {
  data: [],
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    path: null,
    per_page: 15,
    to: null,
    total: 0
  }
};

export interface IResourceService<T> {
  url: string;
  loading$?: Observable<boolean>;
  filter$?: Observable<boolean>;

  getAll(page: number, filters: { [fieldName: string]: any }): Observable<{ data: T[] } | DataSourceModel>;
  get(id: number): Observable<{ data: T }>;
  delete(id: number): Observable<ResourceResponse | void>;
  update(id: number, resource: T): Observable<ResourceResponse | void>;
  create(resource: T): Observable<ResourceResponse | void>;
}

export interface ResourceResponse {
  data: any;
  status: number;
  message: string | string[];
}
