import {Component, Injector, OnInit} from '@angular/core';
import {MessageService} from '../../../../shared/services/message.service';
import {Router} from '@angular/router';
import {toggleFade} from '../../../../widget/animations';
import {PhoneToNotify} from '../../../../shared/models/phone-to-notify';
import {PhoneToNotifyService} from '../../../services/phone-to-notify.service';

@Component({
  selector: 'app-phones-to-notify',
  templateUrl: './phones-to-notify.component.html',
  styleUrls: ['./phones-to-notify.component.scss'],
  animations: [
    toggleFade
  ]
})
export class PhonesToNotifyComponent implements OnInit {
  addPhone = false;

  resource: PhoneToNotify[];
  resourcePhoneToNotify = new PhoneToNotify();
  protected messageService: MessageService;

  constructor(
    private injector: Injector,
    protected router: Router,
    public phoneToNotifyService: PhoneToNotifyService,
    private alert: MessageService,
  ) {
    this.messageService = injector.get(MessageService);
  }

  ngOnInit() {
    this.getPhonesToNotify();
  }

  getPhonesToNotify() {
    this.phoneToNotifyService.getPhones().subscribe(response => {
      this.resource = response.data as PhoneToNotify[];
    });
  }

  deletePhone(id: number) {
    this.messageService.confirm('Czy na pewno chcesz kontynuować?', 'Potwierdź czynność', null, null, () =>
      this.phoneToNotifyService.delete(id)
        .subscribe(() => {
          this.alert.toast('Telefon został usunięty.');
          this.router.navigate(['/configuration'], {skipLocationChange: true});
        }, error => {
          this.alert.toast('Nie można było usunąć telefonu. Spróbój ponownie.');
        })
    );
  }

  onSubmit() {
    this.phoneToNotifyService.create(this.resourcePhoneToNotify)
      .subscribe(() => {
        this.alert.toast('Telefon został dodany.');
        this.router.navigate(['/configuration'], {skipLocationChange: true});
      }, () => {
        this.alert.toast('Nie udało się dodać telefonu. Spróbój ponownie.');
      });
  }

  calendarAction() {
    this.addPhone = !this.addPhone;
  }
}

