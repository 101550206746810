import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseTemplate} from '../../../../shared/components/base.template';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent extends BaseTemplate implements OnInit {

  resourceName = 'Rezerwacje';

  constructor(
    private inject: Injector
  ) {
    super(inject);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
