import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/services/resource.service';
import { Hotel } from '../../shared/models/hotel';

@Injectable({
  providedIn: 'root'
})
export class HotelService extends ResourceService<Hotel> {

  url = '/hotels';

}
