import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserLogin} from '../../../shared/DTOs/user-login';
import {AuthService} from '../../../shared/services/auth.service';
import {Router} from '@angular/router';
import {MessageService} from '../../../shared/services/message.service';
import {AppService} from '../../../shared/services/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  user = new UserLogin();

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.authService.login(this.user)
      .subscribe(response => {
        if (response.status === 0) {
          this.router.navigate(['/']);

          this.messageService.toast(response.message);

          this.appService.updateMenu(response.user.menu);
        }
      });
  }

}
