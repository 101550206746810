import {Component, Injector, OnInit} from '@angular/core';
import {RoomsAvailability} from '../../../../shared/models/rooms-availability';
import {RoomsAvailabilityService} from '../../../services/rooms-availability.service';
import * as moment from 'moment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '../../../../shared/services/message.service';
import {BaseTemplate} from '../../../../shared/components/base.template';

@Component({
  selector: 'app-rooms-availability-table',
  templateUrl: './rooms-availability-table.component.html',
  styleUrls: ['./rooms-availability-table.component.scss']
})
export class RoomsAvailabilityTableComponent extends BaseTemplate implements OnInit {
  resourceName = 'Zarządzanie dostępnością';
  roomsAvailability: RoomsAvailability[];
  formRoomsAvailability = new FormGroup({});

  private scopeFrom = moment().startOf('month');
  private scopeTo = moment().add(2, 'months').endOf('month');
  monthDays = [];
  private previousValue: any;
  scopeText: any;

  constructor(
    private roomsAvailabilityService: RoomsAvailabilityService,
    private messageService: MessageService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getRoomsAvailability();
  }

  getRoomsAvailability() {
    this.roomsAvailabilityService.get(this.scopeFrom, this.scopeTo).subscribe((response) => {
      this.roomsAvailability = response.data as RoomsAvailability[];

      this.roomsAvailability.forEach(ra => {
        this.renderTables(this.scopeFrom, ra);
      });
    });

    this.scopeText = this.scopeFrom.format('MMM YYYY') + ' - ' + this.scopeTo.format('MMM YYYY');
  }

  private renderTables(date, ra: RoomsAvailability) {
    const month = date.toDate().getMonth() + 1;
    const year = date.toDate().getFullYear();
    this.monthDays = [];

    for (let j = 0; j < 3; j++) {

      const daysInMonth = moment(month + '-' + year, 'MM-YYYY').add(j, 'month');
      const days = [];
      for (let i = 1; i <= daysInMonth.daysInMonth(); i++) {
        const dateMoment = moment([daysInMonth.toDate().getFullYear(), daysInMonth.toDate().getMonth(), i]);
        const disabled = dateMoment.diff(moment(), 'days') < 0;

        days.push({
          'date': dateMoment,
        });

        const field = dateMoment.format('YYYY-MM-DD') + '_' + ra.id;
        this.formRoomsAvailability.addControl(field, new FormControl({value: ra.rooms, disabled: disabled}, [Validators.required]));
      }

      this.monthDays.push(days);
    }

    this.updateAvailability(ra);
  }

  incrementCalendarScope() {
    this.scopeFrom.add(3, 'month');
    this.scopeTo.add(3, 'month');
    this.getRoomsAvailability();
  }

  decrementCalendarScope() {
    this.scopeFrom.subtract(3, 'month');
    this.scopeTo.subtract(3, 'month');
    this.getRoomsAvailability();
  }

  private updateAvailability(ra: RoomsAvailability) {
    const datesToChange = [];

    for (const reservation of ra.reservations) {
      const dateFrom = moment(reservation.date_from, 'YYYY-MM-DD');
      const dateTo = moment(reservation.date_to, 'YYYY-MM-DD');
      const days = dateTo.diff(dateFrom, 'days');

      for (let i = 0; i <= days; i++) {
        datesToChange.push({
          'date_at': dateFrom.format('YYYY-MM-DD'),
          'group': ra.id,
        });

        dateFrom.add(1, 'day');
      }
    }

    for (const modification of ra.availability_modifications) {
      datesToChange.push({
        'date_at': modification.date_at,
        'group': ra.id,
      });
    }

    for (const asd of datesToChange) {
      try {
        const control = this.formRoomsAvailability.controls[asd.date_at + '_' + asd.group];
        control.setValue(control.value - 1);
      } catch (e) {
      }
    }
  }

  colorize(el: string) {
    const controlValue = this.formRoomsAvailability.get(el).value;

    if (controlValue < 1) {
      return 'cell-danger';
    }

    if (controlValue === 1) {
      return 'cell-orange';
    }

    return 'cell-success';
  }

  onChange(el: string) {
    const info = el.split('_');
    const mod = this.formRoomsAvailability.get(el).value;

    this.roomsAvailabilityService.update(info[1], info[0], this.previousValue - mod).subscribe((response) => {
      this.messageService.toast('Dostępność zaktualizowana poprawnie');
    }, (error) => {
      this.messageService.toast('Nie można zaktualizować dostępności na taką o jaką proszono.');
      this.formRoomsAvailability.get(el).setValue(this.previousValue);
    });
  }

  setPrevValue(el: string) {
    this.previousValue = this.formRoomsAvailability.get(el).value;
  }
}
