import {Injectable} from '@angular/core';
import {MessageService} from './message.service';

@Injectable({
  providedIn: 'root'
})
export class CopyInputService {

  constructor(
    private toast: MessageService,
  ) {
  }

  copyInput(inputElement, toastText = '') {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toast.toast(toastText ? toastText : 'Skopiowano');
  }
}
