import {Component, Injector} from '@angular/core';
import {ListTemplate} from '../../../../shared/components/list.template';
import {Settlement} from '../../../../shared/models/settlement';
import {SettlementService} from '../../../services/settlement.service';

@Component({
  selector: 'app-settlement-list',
  templateUrl: './settlement-list.component.html',
  styleUrls: ['./settlement-list.component.scss']
})
export class SettlementListComponent extends ListTemplate<Settlement> {

  resourceName = 'Rozliczenie';
  displayedColumns = ['id', 'dates', 'to_paid', 'actions'];

  constructor(
    private settlementService: SettlementService,
    private injector: Injector
  ) {
    super(injector);
    this.service = settlementService;
  }

  open(settlement: Settlement) {
    this.settlementService.getInvoice(settlement.id).subscribe((response) => {
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL, '_blank');
    });
  }
}
