import {Component, Injector} from '@angular/core';
import {Package} from '../../../../shared/models/package';
import {PackageService} from '../../../services/package.service';
import {ListTemplate} from '../../../../shared/components/list.template';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent extends ListTemplate<Package> {

  displayedColumns = ['picture', 'name', 'duration', 'price', 'actions'];
  resourceName = 'Pokoje';

  constructor(
    public packageService: PackageService,
    private injector: Injector,
  ) {
    super(injector);

    this.service = this.packageService;
  }

}
