import { Component, Injector } from '@angular/core';
import { ListTemplate } from '../../../../shared/components/list.template';
import { Reservation } from '../../../../shared/models/reservation';
import { ReservationService } from '../../../services/reservation.service';

@Component({
  selector: 'app-reservation-list',
  templateUrl: './reservation-list.component.html',
  styleUrls: ['./reservation-list.component.scss']
})

export class ReservationListComponent extends ListTemplate<Reservation> {

  displayedColumns = ['name', 'stay', 'price', 'display_status', 'created_at', 'actions'];
  resourceName = 'Rezerwacje';

  constructor(
    private injector: Injector,
    public reservationService: ReservationService,
  ) {
    super(injector);

    this.service = this.reservationService;
  }
}
