import { Component, Injector } from '@angular/core';
import { ListTemplate } from '../../../../shared/components/list.template';
import { RoomGroupStandard } from '../../../../shared/models/room-group-standard';
import { RoomGroupStandardService } from '../../../services/room-group-standard.service';

@Component({
  selector: 'app-rgs-list',
  templateUrl: './rgs-list.component.html',
  styleUrls: ['./rgs-list.component.scss']
})
export class RgsListComponent extends ListTemplate<RoomGroupStandard> {

  displayedColumns = ['name', 'actions'];
  resourceName = 'Standardy pokojów';

  constructor(
    public rgtService: RoomGroupStandardService,
    private injector: Injector,
  ) {
    super(injector);

    this.service = this.rgtService;
  }

}
