import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { PasswordReset } from '../../../shared/DTOs/password-reset';
import { MessageService } from '../../../shared/services/message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email: string = '';
  resetPassword = new PasswordReset();

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(map => {
      if (map.keys.includes('hash')) {
        this.resetPassword.token = map.get('hash');
      }
    });
  }

  onRequestSubmit() {
    this.authService.requestResetPassword(this.email)
      .subscribe(response => this.messageService.info(response.message, 'Informacja'));
  }

  onResetSubmit() {
    this.authService.resetPassword(this.resetPassword)
      .subscribe(response => this.messageService.info(response.message, 'Informacja'));
  }

}
