import {
  Component,
  Inject,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import {AppConfig, APP_CONFIG} from '../../../app.config';
import {DropzoneConfigInterface, DropzoneDirective} from 'ngx-dropzone-wrapper';
import {AuthService} from '../../../shared/services/auth.service';
import {Image} from '../../../shared/models/image';
import {MessageService} from '../../../shared/services/message.service';
import {DzUploaderService} from '../../services/dz-uploader.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dz-uploader',
  templateUrl: './dz-uploader.component.html',
  styleUrls: ['./dz-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DzUploaderComponent implements AfterViewInit {

  @ViewChild(DropzoneDirective, {static: true}) dropzoneRef: DropzoneDirective;
  @Output('success') success: EventEmitter<Event> = new EventEmitter<Event>();
  @Output('error') error: EventEmitter<Event> = new EventEmitter<Event>();
  @Output('delete') delete: EventEmitter<Partial<Image>> = new EventEmitter<Partial<Image>>();
  @Input('existing') existing: Image[];

  dropzone = null;

  dzConfig: DropzoneConfigInterface = {
    url: this.config.chunkUpload,
    chunking: true,
    chunkSize: 1024 * 1024,
    dictDefaultMessage: 'Przeciągnij pliki tutaj lub kliknij',
    headers: {
      'Authorization': `Bearer ${this.authService.token}`
    },
  };

  thumbnails: Partial<Image>[] = [];

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private authService: AuthService,
    private messageService: MessageService,
    private dzUploaderService: DzUploaderService
  ) {
  }

  onSuccess(event: Event) {
    this.success.emit(event);
  }

  onError(event: Event) {
    this.error.emit(event);
  }

  onDelete(event: Partial<Image>) {
    this.delete.emit(event);
  }

  onComplete(event) {
    this.dropzone.removeFile(event);
    this.addThumbnail(event);
  }

  ngAfterViewInit() {
    this.dropzone = this.dropzoneRef.dropzone();
    this.existing.forEach(image => this.addThumbnail(image));
  }

  previewImage(image: Partial<Image>) {
    this.messageService.imagePreview(`<img src="${image.url}" class="img-responsive">`);
  }

  deleteImage(image: Partial<Image>) {
    this.messageService.confirm('Czy na pewno chcesz usunąć to zdjęcie?', null, 'Usuń', 'Anuluj', () => {
      this.dzUploaderService.deleteImage(image)
        .subscribe(response => {
          this.messageService.toast(response.message);

          const idx = this.thumbnails.indexOf(image);
          if (idx > -1) {
            this.thumbnails.splice(idx, 1);
            this.onDelete(image);
          }
        });
    });
  }

  private addThumbnail(image: Partial<Image>) {
    if (image instanceof File) {
      const data = JSON.parse((image as any).xhr.response).data;

      image = {
        id: data.id,
        url: data.url,
        name: data.name,
        alt: data.alt,
        size: data.size
      };
    }

    this.thumbnails.push(image);
  }

  dragDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.thumbnails, event.previousIndex, event.currentIndex);

    this.dzUploaderService.reorderImages(this.thumbnails.map((img, index) => ({
      id: img.id,
      order: index
    } as Image))).subscribe(() => {
      this.messageService.toast('Kolejność zdjęć została zaktualizowana.');
    }, () => {
      this.messageService.toast('Nie mogliśmy teraz zmienić kolejności.');
    });
  }

}
