import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss']
})
export class IconPickerComponent implements OnInit {

  @Input('icon') iconPrepared: string;
  @Output() iconSelected = new EventEmitter<string>();
  @Input() icon: string;

  icons: Array<string> = [
    'fas fa-address-book',
    'fas fa-address-card',
    'fas fa-allergies',
    'fas fa-ambulance',
    'fas fa-angle-up',
    'fas fa-angle-down',
    'fas fa-angle-left',
    'fas fa-angle-right',
    'fas fa-arrow-up',
    'fas fa-arrow-down',
    'fas fa-arrow-left',
    'fas fa-arrow-right',
    'fas fa-archive',
    'fas fa-at',
    'fas fa-award',
    'fas fa-basketball-ball',
    'fas fa-bath',
    'fas fa-bed',
    'fas fa-beer',
    'fas fa-bell',
    'fas fa-bicycle',
    'fas fa-birthday-cake',
    'fas fa-blender',
    'fas fa-bolt',
    'fas fa-bone',
    'fas fa-book',
    'fas fa-book-open',
    'fas fa-box',
    'fas fa-box-open',
    'fas fa-briefcase',
    'fas fa-building',
    'fas fa-bus',
    'fas fa-calendar',
    'fas fa-calendar-alt',
    'fas fa-camera',
    'fas fa-car',
    'fas fa-cat',
    'fas fa-chair',
    'fas fa-charging-station',
    'fas fa-check',
    'fas fa-check-circle',
    'fas fa-chess-pawn',
    'fas fa-child',
    'fas fa-circle',
    'fas fa-city',
    'fas fa-clipboard-list',
    'fas fa-clock',
    'fas fa-cloud',
    'fas fa-cloud-sun',
    'fas fa-cocktail',
    'fas fa-coffee',
    'fas fa-cog',
    'fas fa-compass',
    'fas fa-concierge-bell',
    'fas fa-cookie',
    'fas fa-couch',
    'fas fa-credit-card',
    'fas fa-desktop',
    'fas fa-dog',
    'fas fa-door-closed',
    'fas fa-door-open',
    'fas fa-dot-circle',
    'fas fa-envelope',
    'fas fa-exchange-alt',
    'fas fa-exclamation-circle',
    'fas fa-eye',
    'fas fa-fingerprint',
    'fas fa-fire-extinguisher',
    'fas fa-first-aid',
    'fas fa-fish',
    'fas fa-flag',
    'fas fa-gamepad',
    'fas fa-gift',
    'fas fa-glass-martini',
    'fas fa-glasses',
    'fas fa-globe',
    'fas fa-globe-americas',
    'fas fa-grin',
    'fas fa-grin-wink',
    'fas fa-hand-holding',
    'fas fa-hand-paper',
    'fas fa-headphones',
    'fas fa-heart',
    'fas fa-hiking',
    'fas fa-home',
    'fas fa-horse',
    'fas fa-hospital',
    'fas fa-hotel',
    'fas fa-hourglass',
    'fas fa-hourglass-half',
    'fas fa-id-card-alt',
    'fas fa-info',
    'fas fa-info-circle',
    'fas fa-key',
    'fas fa-leaf',
    'fas fa-life-ring',
    'fas fa-location-arrow',
    'fas fa-lock',
    'fas fa-lock-open',
    'fas fa-map',
    'fas fa-map-marker',
    'fas fa-map-marker-alt',
    'fas fa-medal',
    'fas fa-money-bill',
    'fas fa-money-check-alt',
    'fas fa-moon',
    'fas fa-mountain',
    'fas fa-music',
    'fas fa-newspaper',
    'fas fa-paper-plane',
    'fas fa-paperclip',
    'fas fa-parking',
    'fas fa-paw',
    'fas fa-pen',
    'fas fa-percent',
    'fas fa-percentage',
    'fas fa-phone',
    'fas fa-phone-square',
    'fas fa-plane',
    'fas fa-plug',
    'fas fa-plus',
    'fas fa-plus-circle',
    'fas fa-question',
    'fas fa-question-circle',
    'fas fa-receipt',
    'fas fa-recycle',
    'fas fa-route',
    'fas fa-running',
    'fas fa-search',
    'fas fa-seedling',
    'fas fa-shield-alt',
    'fas fa-shipping-fast',
    'fas fa-shopping-bag',
    'fas fa-shopping-cart',
    'fas fa-spa',
    'fas fa-star',
    'fas fa-store',
    'fas fa-suitcase',
    'fas fa-sun',
    'fas fa-swimmer',
    'fas fa-taxi',
    'fas fa-theater-masks',
    'fas fa-truck',
    'fas fa-tree',
    'fas fa-tshirt',
    'fas fa-tv',
    'fas fa-user',
    'fas fa-user-circle',
    'fas fa-utensils',
    'fas fa-vector-square',
    'fas fa-volleyball-ball',
    'fas fa-walking',
    'fas fa-wallet',
    'fas fa-wheelchair',
    'fas fa-wifi',
    'fas fa-wind'
  ];

  constructor() { }

  ngOnInit() {
  }

  pickIcon(icon): void {
    if (this.icons.indexOf(icon) !== null) {
      this.icon = icon;
      this.iconSelected.emit(this.icon);
    }
  }
}
