import { GmapService } from './../../../../shared/services/gmap.service';
import { Component, Injector, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormTemplate } from '../../../../shared/components/form.template';
import { Hotel } from '../../../../shared/models/hotel';
import { HotelService } from '../../../services/hotel.service';
import { Option } from '../../../../shared/models/app';
import { AttributeService } from '../../../services/attribute.service';
import { Attribute } from '../../../../shared/models/attribute';
import { Image } from '../../../../shared/models/image';
import { _indexOf } from '../../../../shared/helpers';

@Component({
  selector: 'app-hotel-form',
  templateUrl: './hotel-form.component.html',
  styleUrls: ['./hotel-form.component.scss'],
  providers: [
    GmapService
  ]
})
export class HotelFormComponent extends FormTemplate<Hotel> implements OnInit {

  @ViewChild('gmap', { static: false }) set gmap(content: ElementRef) {
    if (content) {
      const pos = this.resource.address.position;

      this.gmapService.createMap(content, pos, 13, true);

      if (!pos || !pos.lat || !pos.lng) {
        this.runGeocoder();
      } else {
        this.gmapService.setLocation(pos);
      }
    }
  }

  resourceName = 'hotel';
  resource = new Hotel();
  options: Option[] = [];

  constructor(
    public hotelService: HotelService,
    private attributeService: AttributeService,
    private gmapService: GmapService,
    private injector: Injector
  ) {
    super(injector);
    this.service = this.hotelService;
  }

  ngOnInit() {
    super.ngOnInit();

    this.gmapService.markerPosition$.subscribe(position => this.resource.address.position = position);

    this.attributeService.listAll(this.resourceId)
      .subscribe(response => this.options = response.data.map<Option>(attribute => ({ id: attribute.id, content: attribute.content } as Option)));
  }

  onSuccess(event: Event) {
    const response = JSON.parse(event[0].xhr.response).data as Image;

    this.resource.images.push(response);
  }

  onImageDelete(event: Image) {
    const idx = _indexOf(this.resource.images, event, 'id');

    if (idx > -1) {
      this.resource.images.splice(idx, 1);
    }
  }

  onSubmit() {
    super.onSubmit();

    this.authService.updateUser();
  }

  onValueChanges(event: Option[]) {
    this.resource.attributes = event.map(option => ({ content: option.content, id: option.id } as Attribute));
  }

  runGeocoder() {
    const hasAddress = this.resource.address.postal_code || this.resource.address.city || this.resource.address.building_number;

    this.gmapService.runGeocoder(hasAddress ? `${this.resource.address.postal_code} ${this.resource.address.city}, ${this.resource.address.street} ${this.resource.address.building_number}` : 'Polska');
  }

}
