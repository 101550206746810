import {Component, Injector} from '@angular/core';
import {ListTemplate} from '../../../../shared/components/list.template';
import {RoomGroupType} from '../../../../shared/models/room-group-type';
import {RoomGroupTypeService} from '../../../services/room-group-type.service';

@Component({
  selector: 'app-rgt-list',
  templateUrl: './rgt-list.component.html',
  styleUrls: ['./rgt-list.component.scss']
})
export class RgtListComponent extends ListTemplate<RoomGroupType> {

  displayedColumns = ['name', 'actions'];
  resourceName = 'Typy pokojów';

  constructor(
    public rgtService: RoomGroupTypeService,
    private injector: Injector,
  ) {
    super(injector);
    this.service = this.rgtService;
  }
}
