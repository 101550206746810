import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { UserRegister } from '../../../shared/DTOs/user-register';
import { MessageService } from '../../../shared/services/message.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  user = new UserRegister();

  constructor(
    private authService: AuthService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.authService.register(this.user)
      .subscribe(response => this.messageService.info(response.message));
  }

}
